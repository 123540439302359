import { default as WoWSReactEntity } from '@wg/wows-entities/components/react/WoWSEntity/WoWSEntity';
import { items } from '@wg/wows-entities/const';
import DivTooltip from '@wg/wows-react-uikit/DivTooltip';
import Interpolate from '@wg/wows-react-uikit/Interpolate';
import Message from '@wg/wows-react-uikit/Message';
import ProgressBar from '@wg/wows-react-uikit/ProgressBar';
import Tooltip from '@wg/wows-react-uikit/Tooltip';
import TooltipBody from '@wg/wows-react-uikit/TooltipBody';
import TooltipHeader from '@wg/wows-react-uikit/TooltipHeader';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ACHIEVEMENT_GROUP_TYPES } from '~/constants';
import { splitAchievementReceivedCount } from '~/utils/achievements';

import styles from './Achievement.scss';

export interface Achievement_Props extends Achievements {
  isReceived: boolean;
  count?: number;
  lastTime?: number;
  shipId?: number;
}

const Achievement = (props: Achievement_Props) => {
  const {
    title,
    typeTitle,
    battleRestriction,
    receivingRestriction,
    description,
    icons,
    isReceived,
    count,
    lastTime,
    shipId,
    enabled,
    multiple,
    maxProgress,
  } = props;

  const achievementType = props.grouping.group;

  const { t } = useTranslation();
  const icon = isReceived ? icons.default : icons.inactive;

  const renderTooltipAchievementNotEnabled = () => {
    if (enabled) {
      return null;
    }
    return (
      <React.Fragment>
        <div className={styles.tooltipBodyItemHr} />
        <Message style="inline" type="info-round-icon">
          {t('No longer available')}
        </Message>
      </React.Fragment>
    );
  };

  const renderCountReceivedTooltip = () => {
    return multiple ? (
      <Interpolate str={t('Obtained in total: %(count)s')} count={splitAchievementReceivedCount(count)} />
    ) : null;
  };

  const renderProgressReceivedTooltip = () => {
    if (false) {
      const completed = count / maxProgress;
      return (
        <div className={styles.tooltipProgressBar}>
          <ProgressBar completed={completed} size="small" />
          <div>
            {count}&nbsp;/&nbsp;{maxProgress}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderLastDateReceivedTooltip = () => {
    const achievementLastTime = lastTime ? moment(lastTime * 1000).format('DD.MM.YYYY') : '';
    let formatStr = null;
    if (multiple) {
      formatStr = t('Last obtained on: %(date)s');
    } else {
      formatStr = t('Obtained on: %(date)s');
    }
    return (
      <div className={styles.divTooltipTopMargin}>
        <Interpolate str={formatStr} date={achievementLastTime} />
      </div>
    );
  };

  const renderShipReceivedTooltip = () => {
    if (achievementType === ACHIEVEMENT_GROUP_TYPES.BATTLE_TYPE) {
      return (
        <div className={styles.tooltipVehicle}>
          {t('With ship:')}
          &nbsp;
          <WoWSReactEntity
            type={items.VEHICLES}
            id={shipId}
            presentation={{
              withTooltip: false,
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderAchievementTooltipHeader = () => {
    const tooltipIconStyle = {
      background: `url("${icons.default}")`,
    };
    const titleStyle = isReceived ? styles.tooltipTitleTextIsReceived : styles.tooltipTitleTextNotIsReceived;
    const typeTitleStyle = isReceived
      ? styles.tooltipTitleAchievementTypeIsReceived
      : styles.tooltipTitleAchievementTypeNotReceived;
    if (isReceived) {
    }
    return (
      <TooltipHeader isBold>
        <div style={{ display: 'flex' }}>
          <div className={styles.tooltipIcon} style={tooltipIconStyle} />
          <div className={styles.row}>
            <div className={titleStyle}>{title}</div>
            <div className={typeTitleStyle}>{typeTitle}</div>
          </div>
        </div>
      </TooltipHeader>
    );
  };

  const renderBattleRestriction = () => {
    return battleRestriction ? <div className={styles.divTooltipBattleRestriction}>{battleRestriction}</div> : null;
  };

  const renderReceivingRestriction = () => {
    return receivingRestriction ? (
      <div className={styles.divTooltipReceivingRestriction}>{receivingRestriction}</div>
    ) : null;
  };

  const renderTooltip = () => {
    if (isReceived) {
      return (
        <Tooltip>
          {renderAchievementTooltipHeader()}
          <TooltipBody>
            <Message style="inline" type="done">
              {t('Obtained')}
            </Message>
            <div className={styles.tooltipBodyItemHr} />
            {renderCountReceivedTooltip()}
            {renderLastDateReceivedTooltip()}
            {renderShipReceivedTooltip()}
            <div className={styles.tooltipBodyItemHr} />
            <div className={styles.descStyle}>{description}</div>
            {renderProgressReceivedTooltip()}
            {renderBattleRestriction()}
            {renderReceivingRestriction()}
            {renderTooltipAchievementNotEnabled()}
          </TooltipBody>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip>
          {renderAchievementTooltipHeader()}
          <TooltipBody>
            <p className={styles.descStyle}>{description}</p>
          </TooltipBody>
        </Tooltip>
      );
    }
  };
  const DivTooltipStyle = isReceived ? styles.receivedDivTooltip : styles.notReceivedDivTooltip;
  return (
    <div className={styles.wrapper}>
      <DivTooltip className={DivTooltipStyle} tooltipBody={renderTooltip()}>
        <div
          className={styles.icon}
          style={{
            backgroundImage: `url("${icon}")`,
          }}
        >
          {isReceived && count > 1 ? <div className={styles.count}>{`x${count}`}</div> : null}
        </div>
      </DivTooltip>
    </div>
  );
};

export default Achievement;
