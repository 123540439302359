const defaultSettings: Preloaded = {
  settings: {
    languageCode: 'en',
  },
  urls: {
    vortex: '',
    stats: '',
  },
};

const preloaded: Preloaded = window.preloaded ? window.preloaded : defaultSettings;

export default preloaded;
