import axios from 'axios';

import preloaded from '~/preloaded';

const vortexClient = axios.create({
  withCredentials: true,
  baseURL: preloaded.urls.vortex,
});

export default vortexClient;
