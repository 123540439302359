import { Button, CheckboxWithLabel, DialogFooter, DialogHeader, Input, Radio } from '@wg/wows-react-uikit';
import DivTooltip from '@wg/wows-react-uikit/DivTooltip';
import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';
import { isInGame } from '~/utils';

import style from './ProfilePrivacy.scss';

interface ProfilePrivacyModalInterfaceProps {
  onHideDialog: () => void;
  onChange: (name: string) => void;
  onChangeCheckbox: () => void;
  onRefreshLink: () => void;
  clear: () => void;
  setVisibilitySettings: () => void;
  onSaveSettings: () => void;
  openInBrowser: () => void;
  linkValue?: string;
  isPublic: boolean;
  isPrivate: boolean;
  isLink: boolean;
  isCheckBoxVisible: boolean;
  isCheckBox: boolean;
  isClanMember: boolean;
}

interface ProfilePrivacyModalInterfaceState {
  linkFetching: boolean;
}

const stateSelector = (state: State): ProfilePrivacyModalInterfaceState => {
  return {
    linkFetching: state.ReducerApp.linkFetching,
  };
};

const ProfilePrivacyModal: React.FC<ProfilePrivacyModalInterfaceProps> = (props) => {
  const {
    onHideDialog,
    linkValue,
    isPublic,
    isPrivate,
    isLink,
    onChange,
    isCheckBoxVisible,
    onChangeCheckbox,
    isCheckBox,
    onSaveSettings,
    setVisibilitySettings,
    clear,
    onRefreshLink,
    openInBrowser,
    isClanMember,
  } = props;
  const { t } = useTranslation();

  const [isLinkCopied, setLinkCopied] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const { linkFetching } = useSelector<State, ProfilePrivacyModalInterfaceState>(stateSelector, equal);

  useEffect(() => {
    setInputValue(linkValue);
  });

  useEffect(() => {
    return clearInput;
  }, []);

  const clearInput = () => {
    clear();
    setVisibilitySettings();
  };

  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name);
    setLinkCopied(false);
  };

  const onToggleLink = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(linkValue);
  };

  const refreshLink = () => {
    setLinkCopied(false);
    onRefreshLink();
  };

  return (
    <div className={style.wrapper}>
      <DialogHeader>
        <h3 className={style.title}>{t('Profile Privacy Settings')}</h3>
      </DialogHeader>
      <div
        className={classnames(style.content, {
          [style.visibleLinkWithClanMemberContent]: isClanMember && isLink,
          [style.checkBoxVisible]: (isClanMember && isCheckBoxVisible) || (!isClanMember && isLink),
        })}
      >
        <p className={style.radioGroup}>
          <Radio name="public" onChange={onChangeRadio} isChecked={isPublic} value="public">
            <span className={classnames({ [style.active]: isPublic })}> {t('Profile is public.')}</span>
          </Radio>
          <Radio name="private" value="private" onChange={onChangeRadio} isChecked={isPrivate}>
            <span className={classnames({ [style.active]: isPrivate })}>{t('Profile is private.')}</span>
          </Radio>
          <Radio name="link" value="link" onChange={onChangeRadio} isChecked={isLink}>
            <span className={classnames({ [style.active]: isLink })}>{t('Profile is only available via link.')}</span>
          </Radio>
        </p>
        {isClanMember && (
          <p className={style.availability}>
            <CheckboxWithLabel
              labelText={
                <span className={classnames({ [style.active]: isCheckBox })}>{t('Available to Clanmates')}</span>
              }
              onChange={onChangeCheckbox}
              isChecked={isCheckBox}
            />
          </p>
        )}
        <p className={style.link}>
          <Input placeholder={inputValue} value={inputValue} />
          <DivTooltip tooltipBody={<div className={style.tooltip}>{t('Generate new link')}</div>}>
            <button
              onClick={refreshLink}
              className={classnames(style.refreshLink, {
                [style.rotation]: linkFetching,
              })}
            />
          </DivTooltip>
          {isInGame ? (
            <button className={style.external} onClick={openInBrowser}>
              {t('Open in browser')}
            </button>
          ) : (
            <button className={style.copyLink} onClick={onToggleLink}>
              {isLinkCopied ? t('Link copied') : t('Copy link')}
            </button>
          )}
        </p>
      </div>
      <DialogFooter>
        <Button isFlat onClick={onHideDialog}>
          {t('Close')}
        </Button>
        <Button isFlat onClick={onSaveSettings}>
          {t('Save')}
        </Button>
      </DialogFooter>
    </div>
  );
};

export default ProfilePrivacyModal;
