import equal from 'fast-deep-equal/react';
import React, { memo, useRef } from 'react';

import { playButtonClickSound } from '~/helpers/audioApi';

import Cell from './Cell';
import { BasicColumn } from './Table';
import styles from './Table.scss';

export interface Row_Props<Column> {
  index: number;
  columns: Array<Column>;
  renderCell: (column: Column, index: number) => React.ReactNode;

  onClick?: () => void;
  renderAfter?: (index: number) => React.ReactNode;
}

const Row = <Column extends BasicColumn>(props: Row_Props<Column>) => {
  const { columns, renderAfter, renderCell } = props;

  const rowRef = useRef<HTMLDivElement>();

  const onClick = () => {
    if (props.onClick) {
      playButtonClickSound();
      props.onClick();
    }
  };

  const renderColumns = () => {
    return columns.map((column, index) => {
      return (
        <Cell key={`table-cell-${props.index}-${index}`} index={props.index} data={column} renderContent={renderCell} />
      );
    });
  };

  return (
    <>
      <div ref={rowRef} className={styles.row} onClick={onClick}>
        {renderColumns()}
      </div>
      {renderAfter ? renderAfter(props.index) : null}
    </>
  );
};

export default memo(Row, equal);
