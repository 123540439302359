export const SESSION_STORAGE_KEY = 'wg_profile_gloss';
export const SESSION_STORAGE_KEY_LANG = 'wg_profile_gloss_lang';
export const PERSISTED_DATA_KEY = 'wg_profile_persisted_data';

export const SEARCH_MIN_LENGTH = 3;
export const SEARCH_MAX_LENGTH = 24;
export const SEARCH_MAX_ITEMS_COUNT = 50;
export const SEARCH_LIMIT = 10;
export const DEBOUNCE_DELAY = 500;

export enum ERROR_CODES {
  CONFLICT = 409,
}

export const VORTEX_ACCOUNTS = '/api/accounts';
export const VORTEX_AUTOCOMPLETE = '/api/accounts/search/autocomplete/';
export const VORTEX_SEARCH = '/api/accounts/search/';
export const GLOSS = '/api/graphql/glossary/';

export enum ROUTES {
  ROOT = '/',
  PROFILE_ACCESS_CODE = '/statistics/:id/ac/:access_code/',
  PROFILE = '/statistics/:id?/:battletype?/:season?',
  ACHIEVEMENTS = '/achievements/:id?/:sortorder?',
}

export enum Pages {
  STATISTICKS = 'statistics',
  ACHIEVEMENTS = 'achievements',
  SEARCH = 'search',
}

export enum NavigationPlaces {
  SEARCH = 'search',
  MENU = 'menu',
}

export enum FETCHING_KEYS {
  ACCOUNT = 'account',
  CLAN = 'clan',
  STATISTICS = 'statistics',
}

// @ts-ignore
export const SEASONAL_BATTLE_TYPES = []; // ['rank', 'rank_old', 'clan']  No more seasons ):

export const PVP_BATTLE_TYPES = ['pvp', 'pvp_solo', 'pvp_div2', 'pvp_div3'];

export const RANK_BATTLE_TYPES = ['rank_solo', 'rank_div2', 'rank_div3'];

export const RANK_OLD_BATTLE_TYPES = ['rank_old_solo', 'rank_old_div2', 'rank_old_div3'];

export const DIVISIONAL_BATTLE_TYPES = ['pvp', 'rank', 'rank_old'];

export const STAT_BATTLE_TYPES: StatBattleType[] = [
  'pve',
  'pvp',
  'pvp_div2',
  'pvp_div3',
  'rank_solo',
  'rank_div2',
  'rank_div3',
  'rank_old_solo',
  'rank_old_div2',
  'rank_old_div3',
  'club',
  'clan',
];

export const BATTLE_TYPES: BattleTypeList = [
  {
    id: 'pvp',
    selector: 'pvp',
  },
  {
    id: 'rank',
    selector: 'rank',
  },
  {
    id: 'rank_old',
    selector: 'rank',
  },
  {
    id: 'pve',
    selector: 'pve',
  },
];

export const ACHIEVEMENT_SORT_ORDER: AchievementsSortOrderList = [
  {
    id: 'default',
    selector: 'default',
  },
  {
    id: 'date',
    selector: 'date',
  },
  {
    id: 'count',
    selector: 'count',
  },
];

export enum ACHIEVEMENT_TYPES {
  NONE = 'none',
  SERVICE_MEDAL = 'service_medal',
  RESEARCH = 'research',
  ECONOMIC = 'economic',
  HONORABLE = 'honorable',
  HEROIC = 'heroic',
  FESTIVAL = 'festival',
  CLAN = 'clan',
}

export enum ACHIEVEMENT_GROUP_TYPES {
  BATTLE_TYPE = 'battle',
  MEMORABLE = 'memorable',
  ADVANCED = 'advanced',
  EXPLORING = 'exploring',
  NOT_USED = 'not_used',
}

export enum ACHIEVEMENT_SUBGROUP_TYPES {
  OPERATIONS = 'operations',
  RANDOM_OR_RANK = 'random_or_rank',
  BATTLE_OF_ELEMENTS = 'battle_of_elements',
  EVENT_BATTLES = 'event_battles',
  HALLOWEEN = 'halloween',
  COMPETITIONS = 'competitions',
  SINGLE = 'single',
  ACHIEVEMENTS_MARATHON = 'achievements_marathon',
  WOWS_BIRTHDAY = 'wows_birthday',
  SPECIAL = 'special',
  RANKS = 'ranks',
  CLANS = 'clans',
  CAMPAIGNS = 'campaigns',
  COLLECTIONS = 'collections',
  COMMON = 'common',
  NOT_USED = 'not_used',
}

export const SUPERSHIP_ICON = '★';

export const DWH_EVENTS = {
  APP_START: 'app_start',
  OPEN_PAGE: 'page.open',
  INVITE: 'clan_invite_sent',
};
