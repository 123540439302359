import React, { memo } from 'react';

import { Container } from '~/Components/Grid/Grid';
import StatisticsHeader from '~/Components/StatisticsHeader/StatisticsHeader';
import { getStatisticsHeaderData } from '~/utils/statistics';

import styles from './ProfileStatisticsHeader.scss';

export interface ProfileStatisticsHeaderProps {
  statistics: BattleStat;
}

const ProfileStatisticsHeader = (props: ProfileStatisticsHeaderProps) => {
  const statisticsData = getStatisticsHeaderData(props.statistics);

  return (
    <Container className={styles.wrapper}>
      <StatisticsHeader data={statisticsData} />
    </Container>
  );
};

export default memo(ProfileStatisticsHeader);
