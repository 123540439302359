import isNaN from 'lodash/isNaN';

import { SUPERSHIP_ICON } from '~/constants';

export const DEFAULT_ERROR = '—';
export const ROMANS = ['N', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];

const wgsdkThousands = (n: number, reduce = false, startFrom = 0) => {
  let num = n;

  let result = '';
  let i = 0;

  if (reduce && startFrom <= num) {
    let suffix = '';

    if (num >= 1000000) {
      num /= 1000000;
      suffix = ' M';
    } else if (num >= 100000) {
      num /= 1000;
      suffix = ' K';
    }
    result = `${num}`;
    if (suffix) {
      result = num.toFixed(2) + suffix;
    }
    return result;
  }

  let dotted = '';
  let numberStr = num.toString();
  const dotPosition = numberStr.search(/\./);
  if (dotPosition > -1) {
    dotted = ',';
    dotted += numberStr.substr(dotPosition + 1);
    numberStr = numberStr.substr(0, dotPosition);
  }

  result = '';

  let sign = '';
  if (numberStr.substr(0, 1) === '-') {
    numberStr = numberStr.substr(1);
    sign = '-';
  }

  const len = numberStr.length;
  const separator = ' ';

  for (i = 0; i < len; ++i) {
    if (i !== 0 && len - i !== 0 && (len - i) % 3 === 0) {
      result += separator;
    }
    result += numberStr.charAt(i);
  }
  return sign + result + dotted;
};

export const thousands = (value: number, reduceStartFrom = 0) => {
  if (isNaN(value)) {
    return DEFAULT_ERROR;
  }
  return wgsdkThousands(value, !!reduceStartFrom, reduceStartFrom);
};

export const floats = (value: number, digits = 1) => {
  if (isNaN(value)) {
    return DEFAULT_ERROR;
  }

  return wgsdkThousands(round(value, digits));
};

export const percent = (value: number, fixed = 2) => {
  if (isNaN(value)) {
    return DEFAULT_ERROR;
  }

  let text = '0%';
  if (value >= 100) {
    text = '100%';
  } else if (value > 0) {
    text = wgsdkThousands(parseFloat(value.toFixed(fixed))) + '%';
  }
  return text;
};

export const formatShipLevel = (value: number): string => {
  const level = toRoman(value);
  if (level === 'XI') return SUPERSHIP_ICON;
  return level;
};

export const toRoman = (value: number) => {
  return ROMANS[value];
};

export const toArabic = (value: string) => {
  return ROMANS.indexOf(value);
};

export const round = (value: number, ndigits: number) => {
  const shift = Math.pow(10, ndigits);
  return Math.round(value * shift) / shift;
};

export const formatValue = (value: string | number): string => {
  const val = `${value}`;

  if (val.includes('%')) {
    return val;
  }

  if (val.includes('.')) {
    return val;
  }

  return thousands(parseInt(`${val}`));
};
