import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Message from '~/Components/Message/Message';
import SearchPanel from '~/Components/SearchPanel/SearchPanel';

import styles from './NotFound.scss';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.search}>
          <SearchPanel />
        </div>
        <Message text={t('Player not found')} />
      </div>
    </div>
  );
};

export default memo(NotFound);
