import React, { memo, useEffect, useRef, useState } from 'react';

import styles from './Table.scss';

export interface Expand_Props {
  isExpanded: boolean;
  children?: React.ReactNode;
}

const Expand = (props: Expand_Props) => {
  const inner = useRef<HTMLDivElement>();
  const [renderChildren, setRenderChildren] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const isOpenedRef = useRef<boolean>(false);

  const { isExpanded, children } = props;

  useEffect(() => {
    if (isExpanded !== isOpened) {
      if (isOpened) {
        setIsOpened(false);
        isOpenedRef.current = false;
        setTimeout(() => {
          if (!isOpenedRef.current) {
            setRenderChildren(false);
          }
        }, 200);
      } else {
        setRenderChildren(true);
        setIsOpened(true);
        isOpenedRef.current = true;
      }
    }
  }, [isExpanded]);

  return (
    <div
      style={{
        height: `${isOpened ? 600 : 0}px`,
      }}
      className={styles.expand}
    >
      <div className={styles.expandInner} ref={inner}>
        {renderChildren ? children : null}
      </div>
    </div>
  );
};

export default memo(Expand, (prev: Expand_Props, next: Expand_Props) => {
  return prev.isExpanded === next.isExpanded;
});
