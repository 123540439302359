import * as React from 'react';
import * as ReactDOM from 'react-dom';

import initCommonMenu from '~/commonMenu';
import Root from '~/root';

import { initScroll } from './scroll';

ReactDOM.render(<Root />, document.getElementById('app'));

try {
  initCommonMenu();
} catch (e) {
  console.error('Failed to init common menu', e);
}

try {
  initScroll();
} catch (e) {}
