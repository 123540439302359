import classnames from 'classnames';
import React, { useState } from 'react';

import DropPanel from '~/Components/DropPanel/DropPanel';
import { playDropdownClickSound } from '~/helpers/audioApi';
import { useMount } from '~/hooks';

import styles from './Select.scss';

export interface Select_Props {
  maxItemsCount?: number;
  items: Array<any>;
  selectedIndex?: number;
  renderItem: (index: number) => React.ReactNode;
  renderValue: () => React.ReactNode;
  onSelect?: (index: number) => void;
}

const Select = (props: Select_Props) => {
  const { items, maxItemsCount, renderItem, renderValue, onSelect } = props;

  const [isDropPanelVisible, setDropPanelVisible] = useState<boolean>(false);
  const [, setSelectedIndex] = useState<number>(-1);

  useMount(() => {
    if (props.selectedIndex !== undefined) {
      setSelectedIndex(props.selectedIndex);
    }
  });

  const dropPanel = classnames(styles.dropPanel, {
    [styles.dropPanelVisible]: isDropPanelVisible,
  });

  const toggle = classnames(styles.toggle, {
    [styles.toggleActive]: isDropPanelVisible,
  });

  const openPanel = () => {
    setDropPanelVisible(true);
  };

  const closePanel = () => {
    setDropPanelVisible(false);
  };

  const onToggleClick = () => {
    playDropdownClickSound();

    if (isDropPanelVisible) {
      closePanel();
    } else {
      openPanel();
    }
  };

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    closePanel();
    if (onSelect) {
      onSelect(index);
    }
  };

  const onOverlayClick = () => {
    playDropdownClickSound();
    closePanel();
  };

  const maxZindex = 100; //findHighestZIndex()

  return (
    <div className={styles.wrapper}>
      <div className={toggle} onClick={onToggleClick}>
        {renderValue()}
      </div>
      {isDropPanelVisible ? (
        <div
          className={styles.overlay}
          onClick={onOverlayClick}
          style={{
            zIndex: maxZindex + 1,
          }}
        />
      ) : null}
      <div className={styles.dropPanelWrapper}>
        <div
          className={dropPanel}
          style={{
            zIndex: maxZindex + 2,
          }}
        >
          <DropPanel
            maxItemsCount={maxItemsCount}
            isActive={isDropPanelVisible}
            onEsc={() => {}}
            onIndexChange={() => {}}
            onSelect={onSelectHandler}
            renderItem={renderItem}
            items={items}
            selectedIndex={props.selectedIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default Select;
