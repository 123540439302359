import NotificationManager from '@wg/wows-react-uikit/NotificationManager';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { setAccountFetcing } from '~/Actions/ActionApp';
import vortexClient from '~/clients/vortex';
import { VORTEX_ACCOUNTS } from '~/constants';

export const setVisibility = (
  id: string,
  visibilitySettings: VisibilitySettings,
): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(setAccountFetcing(true));
  const url = `${VORTEX_ACCOUNTS}/${id}/visibility/`; //274358
  const data = JSON.stringify(visibilitySettings);
  vortexClient
    .post<VisibilitySettingsResponse>(url, data)
    .then((r) => {
      const visibilitySettings = r.data.data;
      dispatch(updateVisibilitySettings(visibilitySettings));
      NotificationManager.sendWebNotification({
        message: window.notificationMessages.updateVisibilitySetting,
      });
    })
    .catch((err: any) => {
      console.log(err);
      NotificationManager.sendWebNotification({
        message: window.notificationMessages.errorUpdatingVisibilitySettings,
      });
    })
    .finally(() => {
      dispatch(setAccountFetcing(false));
    });
};

export const AccessCodeLink = (id: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(setAccessCodeFetching(true));
  const url = `${VORTEX_ACCOUNTS}/${id}/access_code/`;

  vortexClient
    .get<AccessCodeResponse>(url)
    .then((r) => {
      const access_code = r.data.data.access_code;
      dispatch(setAccessCodeToAccount(access_code));
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setAccessCodeFetching(false));
    });
};

export const UPDATE_VISIBILITY_SETTINGS = 'UPDATE_VISIBILITY_SETTINGS';
export const updateVisibilitySettings = (visibilitySettings: VisibilitySettings) => ({
  type: UPDATE_VISIBILITY_SETTINGS,
  payload: visibilitySettings,
});

export const SET_ACCESS_CODE_FETCHING = 'SET_ACCESS_CODE_FETCHING';
export const setAccessCodeFetching = (flag: boolean) => ({
  type: SET_ACCESS_CODE_FETCHING,
  flag: flag,
});

export const SET_ACCESS_CODE_TO_ACCOUNT = 'SET_ACCESS_CODE_TO_ACCOUNT';
export const setAccessCodeToAccount = (accessCode: string) => ({
  type: SET_ACCESS_CODE_TO_ACCOUNT,
  payload: {
    accessCode,
  },
});
