import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';

import styles from './Flag.scss';

const nationsMapSelector = (state: State) => state.ReducerApp.nationsMap;

export interface Flag_Props {
  nation: string;
  className?: string;
}

const Flag = (props: Flag_Props) => {
  const nationsMap = useSelector<State, NationsMap>(nationsMapSelector, equal);
  const { nation } = props;
  const iconUrl = get(nationsMap, `[${nation}].icons.tiny`, undefined);

  return (
    <div
      className={classnames(styles.wrapper, props.className)}
      style={{
        backgroundImage: `url(${iconUrl})`,
      }}
    />
  );
};

export default memo(Flag, equal);
