/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols

import NotificationManager from '@wg/wows-react-uikit/NotificationManager';
import get from 'lodash/get';
import set from 'lodash/set';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import {
  SetAccount,
  SetAccountAchievements,
  SetAccountFetching,
  SetAutocompleteSuggestions,
  SetClan,
  SetClanFetching,
  SetSearchResults,
  SetShipStatistics,
  SetStatisticsData,
  SetStatisticsDataFetching,
} from '~/Actions/ActionAppType';
import { navigateToProfile } from '~/Actions/ActionNavigation';
import vortexClient from '~/clients/vortex';
import {
  ERROR_CODES,
  ROUTES,
  SEARCH_LIMIT,
  SEARCH_MAX_LENGTH,
  SEARCH_MIN_LENGTH,
  SESSION_STORAGE_KEY,
  SESSION_STORAGE_KEY_LANG,
  VORTEX_ACCOUNTS,
  VORTEX_AUTOCOMPLETE,
  VORTEX_SEARCH,
} from '~/constants';
import gqlClient from '~/gql/client';
import { getGlossDataQuery, getGlossVersionQuery } from '~/gql/queries';
import preloaded from '~/preloaded';
import { State } from '~/Reducers';
import { searchToObject } from '~/utils';
import {
  getBattleTypesMap,
  getDollsMap,
  getNationsMap,
  getVehiclesMap,
  getVehicleTypesIndexMap,
  getVehicleTypesMap,
} from '~/utils/glossary';

export const APP_INIT = 'APP_INIT';
export const appInit = (l: any): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const location: Location = l as Location;
  const state = getState();
  const queryParams = searchToObject();
  if (state.ReducerApp.authSpaId) {
    const spaId = `${state.ReducerApp.authSpaId}`;
    dispatch(getClanData(spaId, true));
    if (location.pathname === ROUTES.ROOT && !queryParams.query) {
      dispatch(navigateToProfile(spaId));
    }
  }
  dispatch({
    type: APP_INIT,
  });
};

export const SEARCH_INIT = 'SEARCH_INIT';
export const searchInit = (): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch(
    getGlossaryData(() => {
      dispatch({
        type: SEARCH_INIT,
      });
    }),
  );
};

export const DROP_ACCOUNT = 'DROP_ACCOUNT';
export const dropAccount = (): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: DROP_ACCOUNT,
  });
};

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const getAccount = (id: string, accessCode = ''): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const clb = () => {
    dispatch(setAccount());
    dispatch(getClanData(id));
    dispatch(getStatisticsData(id, accessCode));
    dispatch(getUserAchievements(id, accessCode));
    dispatch(setAccountFetcing(true));

    let url = `${VORTEX_ACCOUNTS}/${id}`; //274358
    if (accessCode !== '') {
      url = url + `?ac=${accessCode}`;
    }

    vortexClient
      .get<AccountResponse>(url)
      .then((r) => {
        const account = r.data.data[id] as Account;
        account.id = id;
        dispatch(setAccount(account, id));
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setAccountFetcing(false));
      });
  };

  dispatch(getGlossaryData(clb));
};

export const SET_ACCOUNT_FETCHING = 'SET_ACCOUNT_FETCHING';
export const setAccountFetcing = (isFetching: boolean): SetAccountFetching => {
  return {
    type: SET_ACCOUNT_FETCHING,
    payload: {
      isFetching,
    },
  };
};

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const setAccount = (account?: Account, id?: string): SetAccount => {
  return {
    type: SET_ACCOUNT,
    payload: {
      account: account,
      id: id,
    },
  };
};

export const getClanData = (id: string, isOwnClan = false): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch(setClan());
  dispatch(setClanFetching(true));
  const url = `${VORTEX_ACCOUNTS}/${id}/clans/`;
  vortexClient
    .get<ClanDataResponse>(url)
    .then((r) => {
      dispatch(setClan(r.data.data, isOwnClan));
    })
    .catch((err: any) => {
      // console.log('*********', err.response.status)
    })
    .finally(() => {
      dispatch(setClanFetching(false));
    });
};

export const SET_CLAN_FETCHING = 'SET_CLAN_FETCHING';
export const setClanFetching = (isFetching: boolean): SetClanFetching => {
  return {
    type: SET_CLAN_FETCHING,
    payload: {
      isFetching,
    },
  };
};

export const SET_CLAN_DATA = 'SET_CLAN_DATA';
export const setClan = (clanData?: ClanData, isOwnClan = false): SetClan => {
  return {
    type: SET_CLAN_DATA,
    payload: {
      clanData: clanData,
      isOwnClan: isOwnClan,
    },
  };
};

export const getStatisticsData = (id: string, accessCode = ''): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch(setClan());
  dispatch(setStatisticsDataFetcing(true));
  let url = `${VORTEX_ACCOUNTS}/${id}/ships/`;

  if (accessCode !== '') {
    url = url + `?ac=${accessCode}`;
  }

  vortexClient
    .get<ShipStatisticsDataResponse>(url)
    .then((r) => {
      const shipStatisticsAccount = r.data.data[id] as ShipStatisticsAccount;
      const shipsData = shipStatisticsAccount.statistics ? Object.values(shipStatisticsAccount.statistics) : [];
      const shipIds = shipStatisticsAccount.statistics ? Object.keys(shipStatisticsAccount.statistics) : [];
      const mappedShips: Array<TableShip> = shipsData.map((ship, index) => {
        return {
          ...ship,
          id: shipIds[index],
        };
      });
      dispatch(setStatisticsData(mappedShips));
      dispatch(setChartsData(mappedShips));
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setStatisticsDataFetcing(false));
    });
};

export const SET_CHARTS_DATA = 'SET_CHARTS_DATA';
export const setChartsData = (mappedShips: Array<TableShip>): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const nationStatisticsMap: NationsStatisticsMap = {};
  const vehicleTypeStatisticsMap: VehicleTypeStatisticsMap = {};
  const vehicleLevelStatisticsMap: VehicleLevelStatisticsMap = {};

  mappedShips.forEach((mappedShip) => {
    const shipInfo = state.ReducerApp.vehiclesMap[parseInt(mappedShip.id, 10)];
    if (shipInfo) {
      const level = `${shipInfo.level}`;
      for (const battleType in mappedShip) {
        if (battleType === 'seasons') {
          for (const seasonId in mappedShip[battleType]) {
            const seasonData = get(mappedShip, `${battleType}.${seasonId}`, {});
            for (const seasonBattleType in seasonData) {
              const battles_count = get(seasonData, `${seasonBattleType}.battles_count`, 0);
              const prevBattleCountNations = get(
                nationStatisticsMap,
                `${shipInfo.nation.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                0,
              );
              set(
                nationStatisticsMap,
                `${shipInfo.nation.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                prevBattleCountNations + battles_count,
              );

              const prevBattleCountVehicleType = get(
                vehicleTypeStatisticsMap,
                `${shipInfo.type.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                0,
              );
              set(
                vehicleTypeStatisticsMap,
                `${shipInfo.type.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                prevBattleCountVehicleType + battles_count,
              );

              const prevBattleCountVehicleLevel = get(
                vehicleLevelStatisticsMap,
                `${level}.${battleType}.${seasonId}.${seasonBattleType}`,
                0,
              );
              set(
                vehicleLevelStatisticsMap,
                `${level}.${battleType}.${seasonId}.${seasonBattleType}`,
                prevBattleCountVehicleLevel + battles_count,
              );
            }
          }
        } else {
          const battles_count = get(mappedShip, `${battleType}.battles_count`, 0);
          const prevBattleCountNations = get(nationStatisticsMap, `${shipInfo.nation.name}.${battleType}`, 0);
          set(nationStatisticsMap, `${shipInfo.nation.name}.${battleType}`, prevBattleCountNations + battles_count);

          const prevBattleCountVehicleType = get(vehicleTypeStatisticsMap, `${shipInfo.type.name}.${battleType}`, 0);
          set(
            vehicleTypeStatisticsMap,
            `${shipInfo.type.name}.${battleType}`,
            prevBattleCountVehicleType + battles_count,
          );

          const prevBattleCountVehicleLevel = get(vehicleLevelStatisticsMap, `${level}.${battleType}`, 0);
          set(vehicleLevelStatisticsMap, `${level}.${battleType}`, prevBattleCountVehicleLevel + battles_count);
        }
      }
    }
  });

  dispatch({
    type: SET_CHARTS_DATA,
    payload: {
      nationStatisticsMap,
      vehicleTypeStatisticsMap,
      vehicleLevelStatisticsMap,
    },
  });
};

export const SET_STATISTICS_DATA_FETCHING = 'SET_STATISTICS_DATA_FETCHING';
export const setStatisticsDataFetcing = (isFetching: boolean): SetStatisticsDataFetching => {
  return {
    type: SET_STATISTICS_DATA_FETCHING,
    payload: {
      isFetching,
    },
  };
};

export const SET_STATISTICS_DATA = 'SET_STATISTICS_DATA';
export const setStatisticsData = (statisticsData?: Array<TableShip>): SetStatisticsData => {
  return {
    type: SET_STATISTICS_DATA,
    payload: {
      statisticsData: statisticsData,
    },
  };
};

export const SET_BATTLE_TYPE = 'SET_BATTLE_TYPE';
export const setBattleType = (battleType: BattleTypeName): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const defaultDivisions = {
    ['pvp']: '',
    ['pve']: '',
    ['club']: '',
    ['clan']: '',
    ['brawl']: '',
    ['rank']: 'solo',
    ['rank_old']: 'solo',
  };
  dispatch({
    type: SET_BATTLE_TYPE,
    payload: {
      battleType: battleType,
    },
  });
  dispatch({
    type: SET_DIVISION,
    payload: {
      division: defaultDivisions[battleType],
    },
  });
};

export const SET_DIVISION = 'SET_DIVISION';
export const setDivision = (division: DivisionName): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: SET_DIVISION,
    payload: {
      division: division,
    },
  });
};

export const SET_ACHIEVEMENTS_SORT_ORDER = 'SET_ACHIEVEMENTS_SORT_ORDER';
export const setAchievementsSortOrder = (
  achievementsSortOrder: AchievementsSortOrder,
): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
  dispatch({
    type: SET_ACHIEVEMENTS_SORT_ORDER,
    payload: { achievementsSortOrder },
  });
};

export const SET_SEASON_NUMBER = 'SET_SEASON_NUMBER';
export const setSeasonNumber = (seasonNumber?: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: SET_SEASON_NUMBER,
    payload: { seasonNumber },
  });
};

export const GET_GLOSSARY_DATA = 'GET_GLOSSARY_DATA';
export const getGlossaryData = (clb: () => void): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const sessionStorageData = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const sessionStorageDataLang = sessionStorage.getItem(SESSION_STORAGE_KEY_LANG);

  const queryParams = searchToObject();
  const languageCode = queryParams.lang || preloaded.settings.languageCode;

  const updateData = (data: GlossaryQuery) => {
    const vehiclesMap = getVehiclesMap(data);
    const vehicleTypesIndexMap = getVehicleTypesIndexMap(data);
    const vehicleTypesMap = getVehicleTypesMap(data);
    const dollsMap = getDollsMap(data);
    const nationsMap = getNationsMap(data);
    const battleTypesMap = getBattleTypesMap(data);

    dispatch({
      type: GET_GLOSSARY_DATA,
      payload: {
        encyclopedia: data,
        vehiclesMap: vehiclesMap,
        vehicleTypesIndexMap: vehicleTypesIndexMap,
        vehicleTypesMap: vehicleTypesMap,
        dollsMap: dollsMap,
        nationsMap: nationsMap,
        battleTypesMap: battleTypesMap,
      },
    });
    clb();
  };

  const getNewData = (lang: string) => {
    gqlClient
      .query({
        query: getGlossDataQuery,
        variables: {
          lang: lang,
        },
      })
      .then((r: any) => {
        const data: GlossaryQuery = {
          ...r.data,
        };
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
        sessionStorage.setItem(SESSION_STORAGE_KEY_LANG, languageCode);
        updateData(data);
      });
  };

  if (sessionStorageData) {
    const data: GlossaryQuery = JSON.parse(sessionStorageData);

    gqlClient
      .query({
        query: getGlossVersionQuery,
      })
      .then((r: any) => {
        const versionData: GlossaryQuery = {
          ...r.data,
        };

        if (versionData.version !== data.version || languageCode !== sessionStorageDataLang) {
          getNewData(languageCode);
        } else {
          updateData(data);
        }
      })
      .catch((err) => {
        updateData(data);
      });
  } else {
    getNewData(languageCode);
  }
};

export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const setSortField = (sortField: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const sortDirection = state.ReducerApp.sortDirection;
  const curSortField = state.ReducerApp.sortField;
  let newSortDirection = sortDirection;
  if (curSortField === sortField) {
    switch (sortDirection) {
      case -1: {
        newSortDirection = 1;
        break;
      }
      case 1: {
        newSortDirection = -1;
        break;
      }
    }
  } else {
    newSortDirection = -1;
  }
  dispatch({
    type: SET_SORT_FIELD,
    payload: {
      sortField: sortField,
      sortDirection: newSortDirection,
    },
  });
};

export const GET_USERS_AUTOCOMPLETE = 'GET_USERS_AUTOCOMPLETE';
export const getUsersAutocomplete = (query: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  if (query && query.length >= SEARCH_MIN_LENGTH && query.length <= SEARCH_MAX_LENGTH) {
    const url = `${VORTEX_AUTOCOMPLETE}${query}/`;
    vortexClient
      .get<SuggestionsResponse>(url)
      .then((r) => {
        dispatch(setAutocompleteSuggestions(r.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    dispatch(setAutocompleteSuggestions([]));
  }
};

export const SET_AUTOCOMPLETE_SUGGESTIONS = 'SET_AUTOCOMPLETE_SUGGESTIONS';
export const setAutocompleteSuggestions = (suggestions: Suggestions): SetAutocompleteSuggestions => {
  return {
    type: SET_AUTOCOMPLETE_SUGGESTIONS,
    payload: {
      suggestions,
    },
  };
};

export const GET_USERS_SEARCH = 'GET_USERS_SEARCH';
export const getUsersSearch = (query?: string, lastName?: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  if (query) {
    const after = lastName ? `&name_gt=${lastName}` : '';
    const url = `${VORTEX_SEARCH}${query}/?limit=${SEARCH_LIMIT}${after}`;
    vortexClient
      .get<SearchResultsResponse>(url)
      .then((r) => {
        dispatch(setSearchResults(r.data.data, !!lastName));
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    dispatch(setSearchResults([]));
  }
};

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const setSearchResults = (searchResults: SearchResults, append = false): SetSearchResults => {
  return {
    type: SET_SEARCH_RESULTS,
    payload: {
      searchResults,
      append,
    },
  };
};

export const INVITE_TO_CLAN = 'INVITE_TO_CLAN';
export const inViteToClan = (): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const id = state.ReducerApp.spaId;
  const url = `${VORTEX_ACCOUNTS}/${id}/invite_to_clan/`;
  vortexClient
    .post(url)
    .then((r) => {
      NotificationManager.sendWebNotification({
        message: window.notificationMessages.inviteSendMessage,
      });
      dispatch(setInvitedId(id));
    })
    .catch((error) => {
      if (error.response.status === ERROR_CODES.CONFLICT) {
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.inviteSendMessage,
        });
        dispatch(setInvitedId(id));
      } else {
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.inviteSendErrorMessage,
        });
      }
    });
};

export const GET_SHIP_STAT_DATA = 'GET_SHIP_STAT_DATA';
export const getShipStatData = (shipId: string, clb: () => void): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const { battleType, spaId } = state.ReducerApp;

  const url = `${VORTEX_ACCOUNTS}/${spaId}/ships/${shipId}/${battleType}/`;
  vortexClient
    .get<GetShipStatDataResponse>(url)
    .then((r) => {
      const statistics: BattleStat = get(r.data, `data[${spaId}].statistics[${shipId}][${battleType}]`, undefined);
      if (statistics) {
        dispatch(setShipStatistics(statistics));
        clb();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const SET_SHIP_STATISTICS = 'SET_SHIP_STATISTICS';
export const setShipStatistics = (statistics: BattleStat): SetShipStatistics => {
  return {
    type: SET_SHIP_STATISTICS,
    payload: {
      statistics,
    },
  };
};

export const getUserAchievements = (spaId: string, accessCode = ''): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  let url = `${VORTEX_ACCOUNTS}/${spaId}/achievements/`;

  if (accessCode !== '') {
    url = url + `?ac=${accessCode}`;
  }

  vortexClient
    .get<GetAchievementsResponse>(url)
    .then((r) => {
      const achievementsAccount = r.data.data[spaId] as AchievementsAccount;
      if (!achievementsAccount.hidden_profile) {
        dispatch(setAccountAchievements(achievementsAccount.statistics.achievements));
      }
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {});
};

export const SET_ACCOUNT_ACHIEVEMENTS = 'SET_ACCOUNT_ACHIEVEMENTS';
export const setAccountAchievements = (
  achievements: AchievementsAccountStatisticsAchievements,
): SetAccountAchievements => {
  return {
    type: SET_ACCOUNT_ACHIEVEMENTS,
    payload: {
      achievements,
    },
  };
};

export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const toggleFilter = (
  key: string,
  value: string | number | boolean,
  override = false,
): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
  const state = getState();
  const filters = [...state.ReducerApp.filters];

  const strFilter = `${key}#${value}`;
  let newFilters: Array<string> = filters.includes(strFilter)
    ? filters.filter((f) => f !== strFilter)
    : filters.concat([strFilter]);
  if (override) {
    newFilters = newFilters.filter((f) => !f.includes(`${key}#`));
    if (`${value}`.length > 0) {
      newFilters.push(strFilter);
    }
  }

  dispatch({
    type: TOGGLE_FILTER,
    payload: {
      filters: newFilters,
    },
  });
};

export const dropFilters = (): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: TOGGLE_FILTER,
    payload: {
      filters: [],
    },
  });
};

export const SET_SHIP_TABLE_IS_STICKY = 'SET_SHIP_TABLE_IS_STICKY';
export const setShipTableIsSticky = (isSticky: boolean): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: SET_SHIP_TABLE_IS_STICKY,
    payload: {
      isSticky,
    },
  });
};

export const SET_INVITED_ID = 'SET_INVITED_ID';
export const setInvitedId = (id: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: SET_INVITED_ID,
    payload: {
      id,
    },
  });
};

export const SET_SHIP_FILTER_BY_TITLE_VALUE = 'SET_SHIP_FILTER_BY_TITLE_VALUE';
export const setShopFilterByTitleValue = (shipFilterByTitleValue: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  dispatch({
    type: SET_SHIP_FILTER_BY_TITLE_VALUE,
    payload: {
      shipFilterByTitleValue,
    },
  });
};
