import { useEffect, useRef } from 'react';

export type Func = () => void;

export const useMount = (func: Func) => useEffect(() => func(), []);

export const useWillMount = (func: Func) => {
  const willMount = useRef(true);

  if (willMount.current) func();

  willMount.current = false;
};
