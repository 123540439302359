import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import { useMount } from '~/hooks/index';

import styles from './BarChart.scss';

export interface BarChartItem {
  value: number;
  label: string;
  color?: string;
  fontSize?: number;
}

export type BarChartItems = Array<BarChartItem>;

export interface BarChart_Props {
  data: BarChartItems;
  disableTooltip?: boolean;

  renderItemTooltip: (index: number) => React.ReactNode;
}

const BarChart = (props: BarChart_Props) => {
  const { disableTooltip, renderItemTooltip } = props;
  const wrapperRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isResizing, setIsResizing] = useState<boolean>(true);

  const updateSize = () => {
    setIsResizing(true);
    if (wrapperRef.current) {
      setWidth(wrapperRef.current.clientWidth);
      setHeight(wrapperRef.current.clientHeight);
    }
    if (isResizing) {
      setTimeout(() => {
        setIsResizing(false);
      }, 100);
    }
  };

  useMount(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  });

  const onMouseEnter = (index: number) => () => {
    setActiveIndex(index);
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.hide();
      setTimeout(() => {
        window.tooltipProvider.show(renderItemTooltip(index));
      }, 0);
    }
  };

  const onMouseLeave = () => {
    setActiveIndex(-1);
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.hide();
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.update({
        x: e.clientX + 20,
        y: e.clientY,
      });
    }
  };

  const barsCount = props.data.length;
  const spacersCount = barsCount - 1;
  const spacerWidth = 8;
  const totalSpacersWidth = spacersCount * spacerWidth;
  const barWidth = (width - totalSpacersWidth) / barsCount;
  const labelHeight = 20;
  const maxValue = Math.max.apply(
    null,
    props.data.map((item) => item.value),
  );
  const maxBarHeight = height - labelHeight * 2;
  const minBarHeight = 6;

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {width > 0 && height > 0 ? (
        <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
          {props.data.map((item, index) => {
            const isActive = index === activeIndex;
            const barHeightPercent = item.value / (maxValue ? maxValue : 1);
            const barHeight = Math.max(minBarHeight, maxBarHeight * barHeightPercent);
            const y = height - barHeight - labelHeight;
            const x = index * (barWidth + spacerWidth);
            const textX = x + barWidth / 2;
            const textY = height;
            const valueTextY = height - barHeight - labelHeight - spacerWidth;
            const bar = classnames(styles.bar, {
              [styles.barResize]: isResizing,
              [styles.barActive]: isActive,
            });

            return (
              <g
                key={`bar-${index}`}
                onMouseEnter={onMouseEnter(index)}
                onMouseLeave={onMouseLeave}
                onMouseMove={onMouseMove}
              >
                <rect x={x} y={0} width={barWidth} fill={'transparent'} height={height} />
                <rect
                  x={x}
                  y={y}
                  width={barWidth}
                  height={barHeight}
                  fill={'#2df0c9'}
                  stroke={'#2df0c9'}
                  className={bar}
                />
                <text
                  x={textX}
                  y={textY}
                  fill={item.color || '#fff'}
                  textAnchor={'middle'}
                  fontSize={item.fontSize || 14}
                  height={labelHeight}
                >
                  {item.label}
                </text>
                <text x={textX} y={valueTextY} fill={'#fff'} textAnchor={'middle'} fontSize={14} height={labelHeight}>
                  {item.value}
                </text>
              </g>
            );
          })}
        </svg>
      ) : null}
    </div>
  );
};

export default BarChart;
