export const getVehicleTypesMap = (data: GlossaryQuery): VehicleTypesMap => {
  const vehiclesTypesMap: VehicleTypesMap = {};

  data.vehicleTypes.forEach((vehicleType) => {
    vehiclesTypesMap[vehicleType.name] = vehicleType;
  });

  return vehiclesTypesMap;
};
export const getVehiclesMap = (data: GlossaryQuery): VehiclesMap => {
  const vehiclesMap: VehiclesMap = {};

  data.vehicles.forEach((vehicle) => {
    vehiclesMap[vehicle.id] = vehicle;
  });

  return vehiclesMap;
};

export const getVehicleTypesIndexMap = (data: GlossaryQuery): VehicleTypesIndexMap => {
  const vehicleTypesIndexMap: VehicleTypesIndexMap = {};
  data.vehicleTypes.forEach((type, index) => {
    vehicleTypesIndexMap[type.name] = index;
  });
  return vehicleTypesIndexMap;
};

export const getDollsMap = (data: GlossaryQuery): DollsMap => {
  const dollsMap: DollsMap = {};

  if (!data.dogTagComponents || !Array.isArray(data.dogTagComponents)) {
    return dollsMap;
  }

  data.dogTagComponents.forEach((doll) => {
    dollsMap[doll.id] = doll;
  });

  return dollsMap;
};

export const getNationsMap = (data: GlossaryQuery): NationsMap => {
  const nationsMap: NationsMap = {};

  data.nations.forEach((nation) => {
    nationsMap[nation.name] = nation;
  });

  return nationsMap;
};

export const getBattleTypesMap = (data: GlossaryQuery): BattleTypesMap => {
  const battleTypesMap: BattleTypesMap = {};

  data.battleTypes.forEach((battleType) => {
    if (battleType.dossierName) {
      battleTypesMap[battleType.dossierName] = battleType;
    }
  });

  return battleTypesMap;
};

export const getBattleType = (battleTypeRaw: BattleTypeName, division: DivisionName): StatBattleType => {
  return division ? ((battleTypeRaw + '_' + division) as StatBattleType) : (battleTypeRaw as StatBattleType);
};
