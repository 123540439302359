import React from 'react';

import styles from './Menu.scss';

export interface MenuSpacer_Props {
  children: React.ReactNode;
}

const MenuSpacer = (props: MenuSpacer_Props) => {
  return <div className={styles.menuSpacer}>{props.children}</div>;
};

export default MenuSpacer;
