const scrollbar = document.getElementById('scrollbar');
const thumb = document.getElementById('thumb');
let timer: number | null = null;
let mouseOverScrollbar = false;

export const hideScrollThumb = () => {
  thumb.classList.add('thumb-hidden');
};

export const showScrollThumb = () => {
  thumb.classList.remove('thumb-hidden');
};

const scrollContentHandler = () => {
  window.requestAnimationFrame(() => {
    showScrollThumb();
    if (timer !== null) {
      window.clearTimeout(timer);
    }
    if (!mouseOverScrollbar) {
      timer = window.setTimeout(hideScrollThumb, 3000);
    }
    let height = Math.max(5, (window.innerHeight * 100) / document.body.scrollHeight);
    if (height === 100) {
      height = 0;
    }
    thumb.style.height = `${height}%`;
    thumb.style.top = `${(document.scrollingElement.scrollTop * 100) / document.scrollingElement.scrollHeight}%`;
  });
};

let pos = { top: 0, y: 0 };
let dragging = false;

const mouseMoveHandler = (e: any) => {
  if (dragging) {
    const dy = e.clientY - pos.y;
    const scrollRatio = window.innerHeight / document.body.scrollHeight;
    document.scrollingElement.scrollTop = pos.top + dy / scrollRatio;
  }
};

const mouseUpHandler = () => {
  dragging = false;
};

const mouseDownThumbHandler = (e: any) => {
  dragging = true;
  pos = {
    top: document.scrollingElement.scrollTop,
    y: e.clientY,
  };
};

export const initScroll = () => {
  document.addEventListener('scroll', scrollContentHandler);
  scrollbar.addEventListener('mouseenter', () => {
    mouseOverScrollbar = true;
    scrollContentHandler();
  });
  scrollbar.addEventListener('mouseleave', () => {
    mouseOverScrollbar = false;
    scrollContentHandler();
  });
  document.addEventListener('mousemove', mouseMoveHandler);
  document.addEventListener('mouseup', mouseUpHandler);
  thumb.addEventListener('mousedown', mouseDownThumbHandler);
  window.addEventListener('resize', scrollContentHandler);
  hideScrollThumb();
};
