import NotificationManager from '@wg/wows-react-uikit/NotificationManager';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();

  const inviteSendMessage = t('Invite sent.');
  const inviteSendErrorMessage = t('An internal error occurred when sending an invite');

  const updateVisibilitySetting = t('Your profile privacy settings have been saved.');
  const errorUpdatingVisibilitySettings = t('An internal error occurred when updating the privacy settings');

  window.notificationMessages = {
    inviteSendMessage,
    inviteSendErrorMessage,
    updateVisibilitySetting,
    errorUpdatingVisibilitySettings,
  };

  return <NotificationManager />;
};

export default Notifications;
