import className from 'classnames';
import * as React from 'react';

import { playTabClickSound } from '~/helpers/audioApi';

import styles from './Spoiler.scss';

interface ISpoiler {
  id?: string;
  title: React.ReactChild;
  content: React.ReactChild | React.ReactChild[];
  onChange?: (isVisible: boolean, id?: string) => void;
  titleClassName?: string;
  isDefaultVisible?: boolean;
}

const Spoiler = (props: ISpoiler) => {
  const [isVisible, setVisible] = React.useState(props.isDefaultVisible != null && props.isDefaultVisible);
  const changeVisible = () => {
    playTabClickSound();
    setVisible(!isVisible);
    props.onChange && props.onChange(!isVisible, props.id);
  };

  const titleClassNames = className(
    styles.title,
    {
      [styles.active]: isVisible,
    },
    props.titleClassName,
  );

  const contentClassNames = className(styles.content, {
    [styles.active]: isVisible,
  });

  return (
    <div className={styles.wrapper}>
      <div className={titleClassNames} onClick={changeVisible}>
        {props.title}
      </div>
      <div className={contentClassNames}>{props.content}</div>
    </div>
  );
};

export default Spoiler;
