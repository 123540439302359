import classnames from 'classnames';
import chunk from 'lodash/chunk';
import React, { useState } from 'react';
import AnimatedNumber from 'react-animated-number';

import styles from './PieChart.scss';

export interface PieChartItem {
  value: number;
  title: string;
  color?: string;
}

export type PieChartItems = Array<PieChartItem>;

export interface PieChart_Props {
  data: PieChartItems;
  disableTooltip?: boolean;

  renderLegendItem: (index: number) => React.ReactNode;
  renderItemTooltip: (index: number) => React.ReactNode;
}

const S = 100;
const CENTER = S / 2;
const R = (S / 2) * 0.8;

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const getPath = (startAngle: number, endAngle: number) => {
  const start = polarToCartesian(CENTER, CENTER, R, endAngle);
  const end = polarToCartesian(CENTER, CENTER, R, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', R, R, 0, largeArcFlag, 0, end.x, end.y].join(' ');

  return d;
};

const PieChart = (props: PieChart_Props) => {
  const { disableTooltip, renderLegendItem, renderItemTooltip } = props;

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  let summ = 0;
  props.data.forEach((item) => {
    summ += item.value;
  });

  const onMouseEnter = (index: number) => () => {
    setActiveIndex(index);
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.hide();
      setTimeout(() => {
        window.tooltipProvider.show(renderItemTooltip(index));
      }, 0);
    }
  };

  const onMouseLeave = () => {
    setActiveIndex(-1);
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.hide();
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (window.tooltipProvider && !disableTooltip) {
      window.tooltipProvider.update({
        x: e.clientX + 20,
        y: e.clientY,
      });
    }
  };

  const formatValue = (n: number) => n.toFixed(0);

  const centerValue = activeIndex === -1 ? summ : props.data[activeIndex].value;
  const chunkSize = props.data.length > 8 ? 5 : 4;
  const legendColumns = chunk(props.data, chunkSize);

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartWrapper}>
        <svg width="100%" height="100%" viewBox={`0 0 ${S} ${S}`}>
          {props.data.map((item, index) => {
            const p = item.value / summ;
            const angle = 360 * p;
            let angleOffset = 0;
            props.data.forEach((prevItem, i) => {
              if (i < index) {
                const prevP = prevItem.value / summ;
                const prevAngle = 360 * prevP;
                angleOffset += prevAngle;
              }
            });

            const startAngle = angleOffset;
            let endAngle = startAngle + angle;
            if (startAngle === 0 && endAngle === 360) {
              endAngle = endAngle - 0.001;
            }
            const color = item.color ? item.color : '#fff';

            return (
              <path
                className={styles.sector}
                key={`segment-${index}-${item.value}`}
                fill={'transparent'}
                stroke={color}
                strokeWidth={10}
                d={getPath(startAngle, endAngle)}
                onMouseEnter={onMouseEnter(index)}
                onMouseLeave={onMouseLeave}
                onMouseMove={onMouseMove}
              />
            );
          })}
        </svg>
        <div className={styles.value}>
          <AnimatedNumber component="span" value={centerValue} duration={200} formatValue={formatValue} />
        </div>
      </div>
      <div className={styles.legend}>
        {legendColumns.map((column, colIndex) => {
          return (
            <div className={styles.legendColumn} key={`column-${colIndex}`}>
              {column.map((item, i) => {
                const index = chunkSize * colIndex + i;
                const isActive = index === activeIndex;
                const color = item.color ? item.color : '#fff';
                const legendItem = classnames(styles.legendItem, {
                  [styles.legendItemActive]: isActive,
                });

                return (
                  <div
                    key={`column-${colIndex}-${i}`}
                    style={{
                      height: `${100 / chunkSize}%`,
                    }}
                    className={legendItem}
                    onMouseEnter={onMouseEnter(index)}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                  >
                    <div
                      className={styles.legendDot}
                      style={{
                        backgroundColor: color,
                      }}
                    />
                    {renderLegendItem(index)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PieChart;
