import { useTranslation } from 'react-i18next';

import { ACHIEVEMENT_SUBGROUP_TYPES } from '~/constants';

export const getAchievementsByGroupName = (
  groupName: string,
  achievements: Array<Achievements>,
): Array<Achievements> => {
  return achievements.filter((e) => {
    return e.grouping.group === groupName;
  });
};

export const getAchievementsByGroupNameAndSubGroupName = (
  groupName: string,
  subGroupName: string,
  achievements: Array<Achievements>,
): Array<Achievements> => {
  return achievements.filter((e) => {
    return e.grouping.group === groupName && e.grouping.subgroup === subGroupName;
  });
};

export const isAchievementReceived = (
  achievement: Achievements,
  userReceivedAchievements: AchievementsAccountStatisticsAchievements,
): boolean => {
  return userReceivedAchievements && !!userReceivedAchievements[achievement.id];
};

export const filterAchievementsByVisible = (
  achievements: Array<Achievements>,
  userReceivedAchievements: AchievementsAccountStatisticsAchievements,
): Array<Achievements> => {
  return achievements.filter((e) => {
    return isAchievementReceived(e, userReceivedAchievements) || !e.hidden;
  });
};

export const getAccountAchievement = (
  achievement: Achievements,
  userReceivedAchievements: AchievementsAccountStatisticsAchievements,
): AccountAchievement => {
  if (!userReceivedAchievements) {
    return null;
  }
  return userReceivedAchievements[achievement.id];
};

const filterAchievementByReceived = (
  achievements: Array<Achievements>,
  accountAchievements: AchievementsAccountStatisticsAchievements,
  achievementReceived = true,
): Array<Achievements> => {
  return achievements.filter((e) => {
    const received = isAchievementReceived(e, accountAchievements);
    return achievementReceived === received;
  });
};
const sortAchievementByDefault = (achievements: Array<Achievements>): Array<Achievements> => {
  return achievements.sort((a, b) => {
    return a.grouping.sortOrderInGroup - b.grouping.sortOrderInGroup;
  });
};

const defaultSorter = (
  achievements: Array<Achievements>,
  accountAchievements: AchievementsAccountStatisticsAchievements,
  comparator: (a: AccountAchievement, b: AccountAchievement) => any,
): Array<Achievements> => {
  return achievements.sort((a, b) => {
    const a1 = getAccountAchievement(a, accountAchievements);
    const b1 = getAccountAchievement(b, accountAchievements);

    return comparator(a1, b1);
  });
};

const sortAchievementByDate = (
  achievements: Array<Achievements>,
  accountAchievements: AchievementsAccountStatisticsAchievements,
): Array<Achievements> => {
  return defaultSorter(achievements, accountAchievements, (a, b) => {
    const t1 = a != null ? a.last_time : 0;
    const t2 = b != null ? b.last_time : 0;

    return t2 - t1;
  });
};

const sortAchievementByCount = (
  achievements: Array<Achievements>,
  accountAchievements: AchievementsAccountStatisticsAchievements,
): Array<Achievements> => {
  const groupedByCount: { [key: number]: Array<Achievements> } = {};
  achievements.forEach((e) => {
    const accountAchievement = accountAchievements[e.id];
    const recvCount = accountAchievement.count;
    if (groupedByCount[recvCount] == null) {
      groupedByCount[recvCount] = [e];
    } else {
      groupedByCount[recvCount].push(e);
    }
  });

  let result: Array<Achievements> = [];

  const keys: number[] = [];
  // tslint:disable-next-line:forin
  for (const k in groupedByCount) {
    keys.push(parseInt(k, 10));
  }
  const tmp = keys.sort((a, b) => {
    return b - a;
  });

  tmp.forEach((k) => {
    const t = sortAchievementByDefault(groupedByCount[k]);
    result = [...result, ...t];
  });

  return result;
};

export const sortAchievement = (
  achievements: Array<Achievements>,
  accountAchievements: AchievementsAccountStatisticsAchievements,
  achievementsSortOrder: AchievementsSortOrder,
  achievementsGroupName: string,
  achievementsSubGroupName: string,
): Array<Achievements> => {
  if (achievementsSubGroupName === ACHIEVEMENT_SUBGROUP_TYPES.COLLECTIONS) {
    return sortAchievementByDate(achievements, accountAchievements);
  }

  const recvAchievements = filterAchievementByReceived(achievements, accountAchievements, true);
  const notRecvAchievements = filterAchievementByReceived(achievements, accountAchievements, false);
  let recvSorted: Array<Achievements> = [];
  const notRecvSorted = sortAchievementByDefault(notRecvAchievements);

  if (achievementsSortOrder === 'default') {
    recvSorted = sortAchievementByDefault(recvAchievements);
  }

  if (achievementsSortOrder === 'date') {
    recvSorted = sortAchievementByDate(recvAchievements, accountAchievements);
  }

  if (achievementsSortOrder === 'count') {
    recvSorted = sortAchievementByCount(recvAchievements, accountAchievements);
  }

  return [...recvSorted, ...notRecvSorted];
};

export const getLocalizedAchievementsTypes = () => {
  const { t } = useTranslation();
  const result: { [key: string]: string } = {
    service_medal: t('service_medal'),
    research: t('research'),
    economic: t('economic'),
    honorable: t('honorable'),
    heroic: t('heroic'),
    festival: t('festival'),
    clan: t('clan'),
  };
  return result;
};

export const splitAchievementReceivedCount = (count: number, delimiter = ' ', groupLen = 3): string => {
  /*
        transform 1000000 to 1 000 000
         */
  const countStr = String(count);
  if (countStr.length <= groupLen) {
    return countStr;
  }

  const strLen = countStr.length;
  const firstOffset = strLen % groupLen;
  let resultStr = '';
  let index = 0;
  if (firstOffset !== 0) {
    resultStr += countStr.substr(0, firstOffset);
    index = firstOffset;
  }
  while (index < strLen) {
    resultStr += delimiter + countStr.substr(index, groupLen);
    index = index + groupLen;
  }

  return resultStr;
};
