import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import find from 'lodash/find';
import get from 'lodash/get';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getShipStatData,
  setShipTableIsSticky,
  setShopFilterByTitleValue,
  setSortField,
  toggleFilter,
} from '~/Actions/ActionApp';
import Autocomplete from '~/Components/Autocomplete/Autocomplete';
import DetailInfo from '~/Components/DetailInfo/DetailInfo';
import Filter from '~/Components/Filter/Filter';
import Expand from '~/Components/Table/Expand';
import Table, { BasicColumn } from '~/Components/Table/Table';
import { playTabClickSound } from '~/helpers/audioApi';
import { State } from '~/Reducers';
import { isInGame } from '~/utils';
import { formatShipLevel, thousands } from '~/utils/formatting';

import styles from './ShipsTable.scss';

export interface TableColumn extends BasicColumn {
  selector: CellSelector;
}

export type TableColumns = Array<TableColumn>;

export interface ShipsTableState {
  vehiclesMap: VehiclesMap;
  vehicleTypesMap: VehicleTypesMap;
  sortField: string;
  sortDirection: SortDirection;
  shipStatistics: BattleStat;
  statisticsData: Array<TableShip>;
  battleType: StatBattleType;
  seasonPickerVisible: boolean;
  seasonNumber?: string;
  shipFilterByTitleValue: string;
}

const stateSelector = (state: State): ShipsTableState => {
  return {
    vehiclesMap: state.ReducerApp.vehiclesMap,
    vehicleTypesMap: state.ReducerApp.vehicleTypesMap,
    sortField: state.ReducerApp.sortField,
    sortDirection: state.ReducerApp.sortDirection,
    shipStatistics: state.ReducerApp.shipStatistics,
    statisticsData: state.ReducerApp.statisticsData,
    battleType: state.ReducerApp.battleType,
    seasonPickerVisible: state.ReducerApp.seasonPickerVisible,
    seasonNumber: state.ReducerApp.seasonNumber,
    shipFilterByTitleValue: state.ReducerApp.shipFilterByTitleValue,
  };
};

export interface ContainFieldsForFilter {
  [nation: string]: Set<string | number | boolean>;
  type: Set<string>;
  level: Set<number>;
  shipType: Set<string>;
}

export interface ShipsTable_Props {
  sortedShips: Array<TableShipStat>;
  allShips: Array<TableShipStat>;
}

const ShipsTable = (props: ShipsTable_Props) => {
  const columns: TableColumns = [
    {
      selector: 'nation',
    },
    {
      selector: 'class',
    },
    {
      selector: 'level',
    },
    {
      selector: 'name',
    },
    {
      selector: 'battles_count',
    },
    {
      selector: 'wins',
    },
    {
      selector: 'premium_exp',
    },
  ];

  const dispatch = useDispatch();
  const state = useSelector<State, ShipsTableState>(stateSelector, equal);
  const {
    vehiclesMap,
    vehicleTypesMap,
    sortField,
    sortDirection,
    shipStatistics,
    statisticsData,
    battleType,
    seasonPickerVisible,
    seasonNumber,
    shipFilterByTitleValue,
  } = state;

  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState<string | undefined>();
  const { sortedShips, allShips } = props;

  const [filteredShips, setFilteredShips] = useState<Array<TableShipStat>>([]);

  const containFieldsForFilter: ContainFieldsForFilter = {
    nation: new Set(),
    type: new Set(),
    level: new Set(),
    shipType: new Set(),
  };

  allShips.forEach((ship: TableShipStat) => {
    const shipInfo = vehiclesMap[ship.id];
    containFieldsForFilter.nation.add(shipInfo.nation.name);
    containFieldsForFilter.type.add(shipInfo.type.name);
    containFieldsForFilter.level.add(shipInfo.level);
    if (shipInfo.isSpecial) {
      containFieldsForFilter.shipType.add('isSpecial');
    } else if (shipInfo.isPremium) {
      containFieldsForFilter.shipType.add('isPremium');
    }
  });

  const onRowClick = (item: TableShipStat) => {
    if (item.id === expandedId) {
      setExpandedId(undefined);
    } else {
      const open = () => {
        dispatch(
          getShipStatData(item.id, () => {
            setExpandedId(item.id);
          }),
        );
      };
      if (expandedId) {
        setExpandedId(undefined);
        setTimeout(open, 300);
      } else {
        open();
      }
    }
  };

  const renderCell = (column: TableColumn, index: number) => {
    const item = sortedShips[index];
    const shipInfo = vehiclesMap[item.id];
    if (!shipInfo) {
      return '';
    }
    const vehicleType = vehicleTypesMap[shipInfo.type.name];
    const textClassName = classnames(styles.text, {
      [styles.yellow]: shipInfo.isPremium || shipInfo.isSpecial,
      [styles.boldCaps]: column.selector === 'name' || column.selector === 'level',
    });
    const statItem = find(statisticsData, { id: item.id }, undefined);

    let statData = get(statItem, battleType, undefined);
    if (seasonPickerVisible) {
      statData = get(statItem, `seasons.${seasonNumber}.${battleType}`, undefined);
    }

    switch (column.selector) {
      case 'nation': {
        return (
          <div
            className={styles.nationCellIcon}
            style={{
              backgroundImage: `url(${shipInfo.nation.icons.tiny})`,
            }}
          />
        );
      }
      case 'class': {
        let iconUrl = vehicleType.icons.normal;
        if (shipInfo.isSpecial) {
          iconUrl = vehicleType.icons.special;
        }
        if (shipInfo.isPremium) {
          iconUrl = vehicleType.icons.premium;
        }
        return (
          <div
            className={styles.nationIcon}
            style={{
              backgroundImage: `url(${iconUrl})`,
            }}
          />
        );
      }
      case 'level': {
        return <div className={textClassName}>{formatShipLevel(shipInfo.level)}</div>;
      }
      case 'name': {
        return (
          <div className={styles.shipCellName}>
            <div
              className={styles.shipCellIcon}
              style={{
                backgroundImage: `url(${shipInfo.icons.small})`,
              }}
            />
            <div className={textClassName}>{shipInfo.titleShort}</div>
          </div>
        );
      }
      case 'battles_count': {
        return <div className={textClassName}>{get(statData, column.selector, 0)}</div>;
      }
      case 'wins': {
        return <div className={textClassName}>{get(statData, column.selector, 0)}</div>;
      }
      case 'premium_exp': {
        return <div className={textClassName}>{thousands(get(statData, column.selector, 0))}</div>;
      }
      default: {
        return <div>{column.selector}</div>;
      }
    }
  };

  const renderHeaderCell = (column: TableColumn) => {
    switch (column.selector) {
      case 'premium_exp': {
        return (
          <div className={styles.xpCount}>
            <span>{t('XP')}</span>
          </div>
        );
      }
      case 'wins': {
        return (
          <div className={styles.winsCount}>
            <span>{t('Victories:duplicated0')}</span>
          </div>
        );
      }
      case 'battles_count': {
        return (
          <div className={styles.battlesCount}>
            <span>{t('Battles:duplicated0')}</span>
          </div>
        );
      }
      case 'name': {
        return <div className={styles.shipName}>{t('Warship name')}</div>;
      }
      case 'level': {
        return <span>{`I-X`}</span>;
      }
      case 'class': {
        return <div className={styles.classIcon} />;
      }
      case 'nation': {
        return <div className={styles.nationIcon} />;
      }
      default: {
        return <div>{column.selector}</div>;
      }
    }
  };

  const renderAfterRow = (index: number) => {
    const id = sortedShips[index].id;
    const isExpanded = expandedId === id;

    return (
      <Expand isExpanded={isExpanded}>
        <DetailInfo statistics={shipStatistics} />
      </Expand>
    );
  };

  const onHeaderCellClick = (column: TableColumn) => {
    playTabClickSound();
    dispatch(setSortField(column.selector));
  };

  const onTableHeaderStickyStateChange = (isSticky: boolean) => {
    dispatch(setShipTableIsSticky(isSticky));
  };

  const onShipInputRenderItem = (index: number) => {
    const item = filteredShips[index];
    return <div>{vehiclesMap[item.id].title}</div>;
  };

  const onShipInputSelect = (index: number) => {
    const item = filteredShips[index];
    onShipNameChange(vehiclesMap[item.id].title);
  };

  const onShipNameChange = (value: string) => {
    dispatch(setShopFilterByTitleValue(value));
    dispatch(toggleFilter('title', value, true));
    setFilteredShips(filterShips(value));
  };

  const filterShips = (title: string) => {
    if (title === null || title.match(/^ *$/) !== null) {
      return [];
    }
    title = title.toLowerCase();
    return allShips.filter((item) => {
      const vehicleTitle = vehiclesMap[item.id].title.toLowerCase();
      return vehicleTitle.includes(title);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('Warships')}</div>
      <div className={styles.filters}>
        <Filter containFieldsForFilter={containFieldsForFilter} />
        <Autocomplete
          placeholder={t('Warship name')}
          value={shipFilterByTitleValue}
          suggestions={filteredShips}
          onChange={onShipNameChange}
          renderItem={onShipInputRenderItem}
          onSelect={onShipInputSelect}
        />
      </div>
      <Table
        items={sortedShips}
        columns={columns}
        onRowClick={onRowClick}
        onHeaderCellClick={onHeaderCellClick}
        renderCell={renderCell}
        renderHeaderCell={renderHeaderCell}
        renderAfter={renderAfterRow}
        sort={sortField}
        sortDirection={sortDirection}
        onHeaderStickyStateChange={onTableHeaderStickyStateChange}
        topOffset={isInGame ? 107 : 144}
      />
    </div>
  );
};

export default memo(ShipsTable, equal);
