import classnames from 'classnames';
import React, { memo } from 'react';

import { Col, Container, Row } from '~/Components/Grid/Grid';
import { formatValue } from '~/utils/formatting';

import styles from './Statistics.scss';

export type Value = string | number;

export interface StatisticsListItem {
  label: string;
  value?: Value | Array<Value>;
  spacer?: boolean;
}

export type StatisticsList = Array<StatisticsListItem>;

export type StatisticsColumn = Array<StatisticsList>;

export interface Statistics_Props {
  data: Array<StatisticsColumn>;
}

const Statistics = (props: Statistics_Props) => {
  const renderValue = (value: Value, parentIndex: number, index?: number) => {
    return (
      <span key={`stat-column-list-item-value-${parentIndex}-${index ? index : ''}`}>
        {`${value}`.length > 0 ? formatValue(value) : null}
      </span>
    );
  };

  return (
    <Container className={styles.wrapper}>
      <Row>
        {props.data.map((column: StatisticsColumn, i: number) => {
          return (
            <Col size={4} key={`stat-column-${i}`}>
              {column.map((list: StatisticsList, j: number) => {
                return (
                  <ul key={`stat-column-list-${j}`} className={styles.statistisList}>
                    {list.map((item: StatisticsListItem, index: number) => {
                      const row = classnames(styles.row, {
                        [styles.rowTitle]: index === 0,
                        [styles.itemSpacer]: !!item.spacer,
                      });

                      const label = classnames(styles.label, {
                        [styles.labelTitle]: index === 0,
                      });

                      return (
                        <li key={`stat-column-list-item-${index}`} className={row}>
                          <span className={label}>{item.label}</span>
                          <div className={styles.rowValues}>
                            {Array.isArray(item.value)
                              ? item.value.map((value, valueIndex) => renderValue(value, index, valueIndex))
                              : renderValue(item.value, index)}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default memo(Statistics);
