import numeral from 'numeral';
export interface QueryParams {
  [key: string]: string | boolean | number;
  devMode?: boolean;
  superTest?: boolean;
  i18nDebug?: boolean;
}

export const getQueryParams = (): QueryParams => {
  const pairs = window.location.search.substring(1).split('&');
  const obj: QueryParams = {};

  for (const i in pairs) {
    if (pairs[i] === '') {
      continue;
    }

    const pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]) === 'undefined' ? true : decodeURIComponent(pair[1]);
  }

  return obj;
};

export const isInGame = window.navigator.userAgent.includes('WOWS/1.0');

export const findHighestZIndex = (elem = 'div') => {
  const elems = document.getElementsByTagName(elem);
  let highest = Number.MIN_SAFE_INTEGER || -(Math.pow(2, 53) - 1);
  for (let i = 0; i < elems.length; i++) {
    const zindex = Number.parseInt(
      document.defaultView.getComputedStyle(elems[i], null).getPropertyValue('z-index'),
      10,
    );
    if (zindex > highest) {
      highest = zindex;
    }
  }
  return highest;
};

export const isInt = (n: number) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const roundNumbers = (num: number, isPercent = false) => {
  const n = numeral(isPercent ? num / 100 : num);
  const f = isFloat(num) ? '0.00' : '0';
  const percent = isPercent ? '%' : '';
  const format = `${f}${percent}`;

  const result = n.format(format);

  return result;
};

export const getEmptyBarStats = () => {
  const data: any = {};

  for (let index = 0; index < 10; index++) {
    data[index + 1] = {
      clan: 0,
      club: 0,
      id: 0,
      pve: 0,
      pvp: 0,
      rank_old_solo: 0,
      rank_solo: 0,
    };
  }

  return data;
};

export interface QueryObj {
  [key: string]: string | boolean | number;
  lang?: string;
}

export const searchToObject = (): QueryObj => {
  const pairs = window.location.search.substring(1).split('&');
  const obj: QueryObj = {};

  for (const i in pairs) {
    if (pairs[i] === '') {
      continue;
    }

    const pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]) === 'undefined' ? true : decodeURIComponent(pair[1]);
  }

  return obj;
};
