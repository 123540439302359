import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { appInit } from '~/Actions/ActionApp';
import { useWillMount } from '~/hooks';

const Init = () => {
  const location = useLocation<any>();
  const dispatch = useDispatch();
  useWillMount(() => {
    dispatch(appInit(location));
  });

  return <></>;
};

export default Init;
