import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';

import styles from './DogTag.scss';

export interface ProfileState {
  dollsMap: DollsMap;
  account?: Account;
}

const stateSelector = (state: State): ProfileState => {
  return {
    dollsMap: state.ReducerApp.dollsMap,
    account: state.ReducerApp.account,
  };
};

export interface DogTag_Props {
  clanTag?: string;
  small?: boolean;
}

const getColor = (color: string) => {
  return color.replace('0x', '#');
};

const DogTag = (props: DogTag_Props) => {
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const { clanTag } = props;
  const { dollsMap, account } = state;

  if (!account.dog_tag) return null;

  const symbol = dollsMap[account.dog_tag.symbol_id];
  const background = dollsMap[account.dog_tag.background_id];
  const backgroundColor = dollsMap[account.dog_tag.background_color_id];
  const borderColor = dollsMap[account.dog_tag.border_color_id];
  const texture = dollsMap[account.dog_tag.texture_id];
  const isColorizable = !!(background && background.isColorizable);
  let textureData = null;
  let backgroundImage = get(background, 'icons.medium');
  let borderImage = null;
  const symbolImage = get(symbol, 'icons.medium');

  if (isColorizable && texture && Array.isArray(texture.textureData)) {
    textureData = texture.textureData.find((el) => el.id === background.id);
    backgroundImage = textureData.background.medium;
    borderImage = textureData.border.medium;
  }

  if (!symbolImage) return null;
  if (isColorizable && !backgroundImage) return null;

  const showClanTag = (background && background.showClanTag) || (symbol && symbol.showClanTag);
  const hasBrackets = !!backgroundImage;
  const clanTagColor = get(background, 'clanTag.fontColor') || get(symbol, 'clanTag.fontColor') || 'white';

  const clantag = classnames(styles.clantag, {
    [styles.clantagBottom]: hasBrackets,
  });

  const tag = hasBrackets ? `[${clanTag}]` : `${clanTag}`;

  const wrapper = classnames(styles.wrapper, {
    [styles.wrapperSmall]: !!props.small,
  });

  return (
    <div className={wrapper}>
      {backgroundImage && (
        <div
          className={classnames(styles.background, styles.component, isColorizable && styles.colorizable)}
          style={{
            // @ts-ignore
            '--image': `url('${backgroundImage}')`,
            backgroundColor: getColor(get(backgroundColor, 'color', '')),
          }}
        >
          <img src={backgroundImage} />
        </div>
      )}
      {borderImage && (
        <div
          className={classnames(styles.border, styles.component, isColorizable && styles.colorizable)}
          style={{
            // @ts-ignore
            '--image': `url('${borderImage}')`,
            backgroundColor: getColor(get(borderColor, 'color', '')),
          }}
        >
          <img src={borderImage} />
        </div>
      )}
      {symbolImage && (
        <div className={classnames(styles.symbol, styles.component)}>
          <img src={symbolImage} />
        </div>
      )}
    </div>
  );
};

export default DogTag;
