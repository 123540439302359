import React, { memo, useRef, useState } from 'react';

import DropPanel from '~/Components/DropPanel/DropPanel';
import { playButtonClickSound, playInputSound } from '~/helpers/audioApi';
import { findHighestZIndex } from '~/utils';

import styles from './Autocomplete.scss';

export type AutocompleteValue = string;

export interface Autocomplete_Props {
  value?: string;
  suggestions: Array<any>;
  renderItem: (index: number) => React.ReactNode;
  onSelect?: (index: number) => void;
  onSearch?: (value: string) => void;
  onChange: (value: string) => void;
  onClearButtonClick?: () => void;
  minLength?: number;
  placeholder?: string;
}

export type Autocomplete = React.FC<Autocomplete_Props>;

const Autocomplete = (props: Autocomplete_Props) => {
  const { value, suggestions, renderItem, onSelect, onChange, onSearch, onClearButtonClick, placeholder } = props;

  const inputRef = useRef<HTMLInputElement>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onTextEnter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIndex(-1);
    onChange(e.target.value);
  };

  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    setIsFocused(false);
    inputRef.current.blur();
  };

  const onClearButtonClickEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    playButtonClickSound();
    onChange('');
    if (onClearButtonClick) {
      onClearButtonClick();
    }
  };

  const onEnterButtonClickEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (onSearch) {
      onSearch(value);
    }
  };

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onBlur();
  };

  const onIndexChange = (index: number) => {
    setSelectedIndex(index);
    if (index === -1) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  };

  const onSelectHandler = (index: number) => {
    onBlur();
    setSelectedIndex(index);
    if (index === -1) {
      onSearch(inputRef.current.value);
    } else {
      onSelect(index);
    }
  };

  const maxZindex = findHighestZIndex();

  return (
    <div className={styles.wrapper} onClick={onFocus}>
      <input
        ref={inputRef}
        onFocus={onFocus}
        type="text"
        placeholder={placeholder}
        onChange={onTextEnter}
        onKeyDown={(event) => {
          if (event.key !== 'Unidentified') {
            playInputSound();
          }
        }}
        className={styles.input}
        value={value}
      />
      {isFocused ? (
        <>
          <div className={styles.suggestionsOverlay} onClick={onOverlayClick} style={{ zIndex: maxZindex + 1 }} />
          <div className={styles.suggestions} style={{ zIndex: maxZindex + 2 }}>
            <DropPanel
              fullwidth
              selectedIndex={selectedIndex}
              items={suggestions}
              isActive={isFocused}
              renderItem={renderItem}
              hasParentFocus={true}
              onIndexChange={onIndexChange}
              onSelect={onSelectHandler}
            />
          </div>
        </>
      ) : null}
      {value && value.length > 0 ? <div className={styles.clearButton} onClick={onClearButtonClickEvent} /> : null}
      {value && value.length > 0 && isFocused ? (
        <div className={styles.enterButton} onClick={onEnterButtonClickEvent} />
      ) : null}
    </div>
  );
};

export default memo(Autocomplete);
