import CheckboxWithLabel from '@wg/wows-react-uikit/CheckboxWithLabel';
import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { dropFilters as dropFiltersAction, setShopFilterByTitleValue, toggleFilter } from '~/Actions/ActionApp';
import Flag from '~/Components/Flag/Flag';
import { Col, Row } from '~/Components/Grid/Grid';
import ShipClass from '~/Components/ShipClass/ShipClass';
import { ContainFieldsForFilter } from '~/Components/ShipsTable/ShipsTable';
import { playButtonClickSound, playDropdownClickSound, playTabClickSound } from '~/helpers/audioApi';
import { State } from '~/Reducers';

import { FilterItem, FilterType, getFilterConfig } from './config';
import styles from './Filter.scss';

export interface ProfileState {
  vehicleTypesMap: VehicleTypesMap;
  nationsMap: NationsMap;
  filters: Array<string>;
}

interface Filter_Props {
  containFieldsForFilter: ContainFieldsForFilter;
}

const stateSelector = (state: State): ProfileState => {
  return {
    vehicleTypesMap: state.ReducerApp.vehicleTypesMap,
    nationsMap: state.ReducerApp.nationsMap,
    filters: state.ReducerApp.filters,
  };
};

const Filter: React.FC<Filter_Props> = ({ containFieldsForFilter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filtersVisible, setfiltersVisible] = useState<boolean>(false);
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const { vehicleTypesMap, nationsMap, filters } = state;

  const hasFilters = filters.length > 0;
  const shipTitleSelectorName = 'title';

  const onChange = (filterValue: FilterItem, filter: FilterType) => () => {
    if (filterValue.bool) {
      dispatch(toggleFilter(`${filterValue.value}`, true));
    } else {
      dispatch(toggleFilter(filter.id, filterValue.value));
    }
  };

  const hideFilters = () => {
    playDropdownClickSound();
    setfiltersVisible(false);
  };
  const showFilters = () => {
    playDropdownClickSound();
    setfiltersVisible(true);
  };
  const dropFilters = () => {
    playButtonClickSound();
    dispatch(dropFiltersAction());
    dispatch(setShopFilterByTitleValue(''));
  };

  const renderFilterLabel = (filter: FilterType, filterValue: FilterItem) => {
    if (filter.id === 'nation.name') {
      return (
        <div className={styles.filterItemLabel}>
          <Flag nation={`${filterValue.value}`} className={styles.nationFilter} />
        </div>
      );
    }
    if (filter.id === 'type.name') {
      return (
        <div className={styles.filterItemLabel}>
          <ShipClass type={`${filterValue.value}`} />
        </div>
      );
    }

    return <div className={styles.filterItemLabel}>{filterValue ? filterValue.title : '?'}</div>;
  };

  const filterConfig = getFilterConfig(nationsMap, vehicleTypesMap, t);

  const filtersMap: FiltersMap = {};
  filters.forEach((filterStr) => {
    const key = filterStr.split('#')[0];
    const val = filterStr.split('#')[1];

    if (filtersMap[key]) {
      filtersMap[key].push(val);
    } else {
      filtersMap[key] = [val];
    }
  });

  const onActiveFiltersGroupClick = (selector: string) => () => {
    playTabClickSound();
    dispatch(toggleFilter(selector, '', true));
    if (selector === shipTitleSelectorName) {
      dispatch(setShopFilterByTitleValue(''));
    }
  };

  const renderActiveFilter = (selector: string, values: Array<string>): React.ReactNode => {
    let filter: FilterType = find(filterConfig, { id: selector });
    if (!filter) {
      filterConfig.forEach((filterConfigItem) => {
        filterConfigItem.values.forEach((filterConfigItemInner) => {
          if (filterConfigItemInner.value === selector) {
            filter = filterConfigItem;
          }
        });
      });
    }
    if (!filter && selector === shipTitleSelectorName) {
      filter = { id: shipTitleSelectorName, title: '', values: [] } as FilterType;
    }

    return (
      <div className={styles.activeFiltersGroup} onClick={onActiveFiltersGroupClick(selector)}>
        {values.map((val: string, index: number) => {
          let v: string | number = val;

          if (!isNaN(parseInt(val))) {
            v = parseInt(val);
          }

          let filterItem: FilterItem = filter && filter.values ? find(filter.values, { value: v }) : null;
          if (!filterItem && filter && filter.id === shipTitleSelectorName) {
            filterItem = { title: v, value: v } as FilterItem;
          }
          const isBool = get(filter, `values[0].bool`, false);
          if (isBool) {
            filterItem = filter && filter.values ? find(filter.values, { value: selector }) : null;
          }
          if (!filterItem) {
            console.log(filter, filterItem, v);
          }
          return (
            <div className={styles.activeFilter} key={`ActiveFilter-${val}-${index}`}>
              {filterItem ? renderFilterLabel(filter, filterItem) : '-'}
            </div>
          );
        })}
      </div>
    );
  };

  const renderActiveFilters = () => {
    const activeFilters: Array<React.ReactNode> = [];

    for (const key in filtersMap) {
      activeFilters.push(renderActiveFilter(key, filtersMap[key]));
    }

    return <div className={styles.activeFilters}>{activeFilters}</div>;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterHolder}>
        {filtersVisible ? (
          <React.Fragment>
            <div className={styles.filterOverlay} onClick={hideFilters} />
            <div className={styles.filterHolderInner}>
              <Row>
                {filterConfig.map((filter: FilterType) => {
                  const chunkValues = filter.values.length > 6 ? chunk(filter.values, 6) : [filter.values];
                  return (
                    <Col key={`Filter-${filter.id}`}>
                      <span className={styles.filterTitle}>{filter.title}</span>
                      <Row>
                        {chunkValues.map((values) => {
                          return (
                            <Col>
                              {values.map((filterValue: FilterItem, j: number) => {
                                const key = filterValue.bool ? filterValue.value : filter.id;
                                const isChecked =
                                  filters
                                    .filter((f) => f.split('#')[0] === key)
                                    .filter((f) => {
                                      let fVal: string | boolean = f.split('#')[1];
                                      if (fVal === 'true' || fVal === 'false') {
                                        fVal = fVal === 'true';
                                        return fVal && filterValue.value === f.split('#')[0];
                                      } else {
                                        return f.split('#')[1] === `${filterValue.value}`;
                                      }
                                    }).length > 0;
                                let isDisabled = true;
                                for (const field in containFieldsForFilter) {
                                  const shipType = filterValue.hasOwnProperty('bool');
                                  if (shipType) {
                                    isDisabled = !(
                                      containFieldsForFilter[field].has(filterValue.value) && filterValue.bool
                                    );
                                  } else {
                                    isDisabled = !containFieldsForFilter[field].has(filterValue.value);
                                  }
                                  if (!isDisabled) {
                                    break;
                                  }
                                }
                                const checkboxWithLabelWrapper = classnames(styles.checkboxWithLabelWrapper, {
                                  [styles.checkboxWithLabelWrapperNarrow]: filter.id !== 'ship_type',
                                });

                                return (
                                  <div className={checkboxWithLabelWrapper} key={`FilterValue-${filter.id}-${j}`}>
                                    <CheckboxWithLabel
                                      isChecked={isChecked}
                                      labelText={renderFilterLabel(filter, filterValue)}
                                      onChange={onChange(filterValue, filter)}
                                    />
                                    {isDisabled && <span className={styles.checkboxDisabledWrapper}></span>}
                                  </div>
                                );
                              })}
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </React.Fragment>
        ) : null}
      </div>
      <div className={styles.filterActions}>
        {filtersVisible ? (
          <div className={styles.showFilterButton} onClick={hideFilters}>
            {t('Hide Filters')}
          </div>
        ) : (
          <div className={styles.showFilterButton} onClick={showFilters}>
            {t('Show filters')}
          </div>
        )}
        {renderActiveFilters()}
        {hasFilters ? (
          <div className={styles.link} onClick={dropFilters}>
            {t('Reset Filters')}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
