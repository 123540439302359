import { combineReducers } from 'redux';

import { ReducerApp, State as AppState } from '~/Reducers/ReducerApp';

const reducers = {
  ReducerApp,
};

export interface State {
  ReducerApp: AppState;
}

export default combineReducers(reducers);
