import React, { memo } from 'react';

import { Col, Container, Row } from '~/Components/Grid/Grid';

import styles from './StatisticsHeader.scss';

export interface StatisticsHeaderItem {
  value: string | number;
  label: string;
}

export interface StatisticsHeader_Props {
  data: Array<StatisticsHeaderItem>;
}

const StatisticsHeader = (props: StatisticsHeader_Props) => {
  if (!props.data || props.data.length === 0) {
    return (
      <Container>
        <Row>
          <Col>{'NO DATA'}</Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className={styles.row}>
        {props.data.map((item: StatisticsHeaderItem, index: number) => {
          return (
            <Col className={styles.cell} key={`stat-item-${item.value}-${index}`}>
              <div className={styles.title}>{item.value}</div>
              <div className={styles.label}>{item.label}</div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default memo(StatisticsHeader);
