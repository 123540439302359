import get from 'lodash/get';

import { Actions } from '~/Actions';
import {
  DROP_ACCOUNT,
  GET_GLOSSARY_DATA,
  SEARCH_INIT,
  SET_ACCOUNT,
  SET_ACCOUNT_ACHIEVEMENTS,
  SET_ACCOUNT_FETCHING,
  SET_ACHIEVEMENTS_SORT_ORDER,
  SET_AUTOCOMPLETE_SUGGESTIONS,
  SET_BATTLE_TYPE,
  SET_CHARTS_DATA,
  SET_CLAN_DATA,
  SET_CLAN_FETCHING,
  SET_DIVISION,
  SET_INVITED_ID,
  SET_SEARCH_RESULTS,
  SET_SEASON_NUMBER,
  SET_SHIP_FILTER_BY_TITLE_VALUE,
  SET_SHIP_STATISTICS,
  SET_SHIP_TABLE_IS_STICKY,
  SET_SORT_FIELD,
  SET_STATISTICS_DATA,
  SET_STATISTICS_DATA_FETCHING,
  TOGGLE_FILTER,
} from '~/Actions/ActionApp';
import {
  SET_ACCESS_CODE_FETCHING,
  SET_ACCESS_CODE_TO_ACCOUNT,
  UPDATE_VISIBILITY_SETTINGS,
} from '~/Actions/ActionVisibility';
import { FETCHING_KEYS, PERSISTED_DATA_KEY, SEASONAL_BATTLE_TYPES } from '~/constants';
import defaultStatistics from '~/defaultStatistics';
import preloaded from '~/preloaded';
import { getBattleType } from '~/utils/glossary';

export interface State {
  isInviteSendMap: IsInviteSendMap;
  filters: Array<string>;
  authSpaId?: number;
  hasOwnClan: boolean;
  ownClanRole?: RoleName;
  achievements: AchievementsAccountStatisticsAchievements;
  encyclopedia: GlossaryQuery;
  fetching: FetchingMap;
  isReady: boolean;
  spaId?: string;
  account?: Account;
  clanData?: ClanData;
  statisticsData: Array<TableShip>;
  battleTypeRaw: BattleTypeName;
  battleType: StatBattleType;
  division: DivisionName;
  achievementsSortOrder: AchievementsSortOrder;
  seasonPickerVisible: boolean;
  seasonNumber?: string;
  sortField: TableSortField;
  sortDirection: SortDirection;
  suggestions: Suggestions;
  searchResults: SearchResults;
  showMoreButton: boolean;
  statistics: BattleStat;
  shipStatistics: BattleStat;
  vehiclesMap: VehiclesMap;
  vehicleTypesIndexMap: VehicleTypesIndexMap;
  vehicleTypesMap: VehicleTypesMap;
  dollsMap: DollsMap;
  nationsMap: NationsMap;
  battleTypesMap: BattleTypesMap;
  nationStatisticsMap: NationsStatisticsMap;
  vehicleTypeStatisticsMap: VehicleTypeStatisticsMap;
  vehicleLevelStatisticsMap: VehicleLevelStatisticsMap;
  isShipTableHeaderSticky: boolean;
  linkFetching: boolean;
  shipFilterByTitleValue: string;
}

const persistedStateStr: string = sessionStorage.getItem(PERSISTED_DATA_KEY);
const persistedState: PersistedState = persistedStateStr ? JSON.parse(persistedStateStr) : undefined;

const initialState: State = {
  isInviteSendMap: persistedState ? persistedState.isInviteSendMap : {},
  filters: [],
  authSpaId: get(preloaded, 'account.id', undefined),
  hasOwnClan: false,
  ownClanRole: undefined,
  achievements: {},
  encyclopedia: {},
  fetching: {
    clan: true,
    statistics: true,
    account: true,
  },
  isReady: false,
  spaId: undefined,
  account: undefined,
  clanData: undefined,
  statisticsData: [],
  battleTypeRaw: 'pvp',
  battleType: 'pvp',
  division: undefined,
  achievementsSortOrder: 'default',
  seasonPickerVisible: false,
  seasonNumber: undefined,
  sortField: 'battles_count',
  sortDirection: 1,
  suggestions: [],
  searchResults: [],
  showMoreButton: true,
  statistics: defaultStatistics,
  shipStatistics: defaultStatistics,
  vehiclesMap: {},
  vehicleTypesIndexMap: {},
  vehicleTypesMap: {},
  dollsMap: {},
  nationsMap: {},
  battleTypesMap: {},
  nationStatisticsMap: {},
  vehicleTypeStatisticsMap: {},
  vehicleLevelStatisticsMap: {},
  isShipTableHeaderSticky: false,
  linkFetching: false,
  shipFilterByTitleValue: '',
};

export const ReducerApp = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case DROP_ACCOUNT: {
      return {
        ...state,
        account: undefined,
        fetching: {
          ...state.fetching,
          account: true,
        },
      };
    }
    case UPDATE_VISIBILITY_SETTINGS: {
      return {
        ...state,
        account: {
          ...state.account,
          visibility_settings: action.payload,
        },
      };
    }
    case SET_ACCESS_CODE_FETCHING: {
      return {
        ...state,
        linkFetching: action.flag,
      };
    }
    case SET_ACCESS_CODE_TO_ACCOUNT: {
      return {
        ...state,
        account: {
          ...state.account,
          visibility_settings: {
            ...state.account.visibility_settings,
            access_code: action.payload.accessCode,
          },
        },
      };
    }
    case SEARCH_INIT: {
      return {
        ...state,
        fetching: {
          account: false,
          clan: false,
          statistics: false,
        },
      };
    }
    case SET_SEASON_NUMBER: {
      return {
        ...state,
        seasonNumber: action.payload.seasonNumber,
      };
    }
    case SET_SORT_FIELD: {
      return {
        ...state,
        sortField: action.payload.sortField,
        sortDirection: action.payload.sortDirection,
      };
    }
    case GET_GLOSSARY_DATA: {
      return {
        ...state,
        encyclopedia: action.payload.encyclopedia,
        vehiclesMap: action.payload.vehiclesMap,
        vehicleTypesIndexMap: action.payload.vehicleTypesIndexMap,
        vehicleTypesMap: action.payload.vehicleTypesMap,
        dollsMap: action.payload.dollsMap,
        nationsMap: action.payload.nationsMap,
        battleTypesMap: action.payload.battleTypesMap,
      };
    }
    case SET_BATTLE_TYPE: {
      // todo: make constant seasonal_modes or check mode in body.seasons
      const seasonPickerVisible = SEASONAL_BATTLE_TYPES.includes(action.payload.battleType);
      const battleType = getBattleType(action.payload.battleType, state.division);
      return {
        ...state,
        battleTypeRaw: action.payload.battleType,
        battleType: battleType,
        seasonPickerVisible: seasonPickerVisible,
      };
    }
    case SET_DIVISION: {
      const battleType = getBattleType(state.battleTypeRaw, action.payload.division);
      return {
        ...state,
        battleType: battleType,
        division: action.payload.division,
      };
    }
    case SET_ACHIEVEMENTS_SORT_ORDER: {
      return {
        ...state,
        achievementsSortOrder: action.payload.achievementsSortOrder,
      };
    }
    case SET_STATISTICS_DATA_FETCHING: {
      const fetching = { ...state.fetching };
      fetching[FETCHING_KEYS.STATISTICS] = action.payload.isFetching;
      return {
        ...state,
        fetching: fetching,
      };
    }
    case SET_STATISTICS_DATA: {
      return {
        ...state,
        statisticsData: action.payload.statisticsData,
      };
    }
    case SET_CLAN_FETCHING: {
      const fetching = { ...state.fetching };
      fetching[FETCHING_KEYS.CLAN] = action.payload.isFetching;
      return {
        ...state,
        fetching: fetching,
      };
    }
    case SET_CLAN_DATA: {
      let hasOwnClan = state.hasOwnClan;
      let ownClanRole = state.ownClanRole;
      if (action.payload.isOwnClan && action.payload.clanData && action.payload.clanData.clan_id) {
        hasOwnClan = true;
        ownClanRole = action.payload.clanData.role as RoleName;
      }
      return {
        ...state,
        clanData: action.payload.clanData,
        hasOwnClan: hasOwnClan,
        ownClanRole: ownClanRole,
      };
    }
    case SET_ACCOUNT_FETCHING: {
      const fetching = { ...state.fetching };
      fetching[FETCHING_KEYS.ACCOUNT] = action.payload.isFetching;

      return {
        ...state,
        fetching: fetching,
      };
    }
    case SET_ACCOUNT: {
      return {
        ...state,
        account: action.payload.account,
        spaId: action.payload.id,
      };
    }
    case SET_AUTOCOMPLETE_SUGGESTIONS: {
      return {
        ...state,
        suggestions: action.payload.suggestions,
      };
    }
    case SET_SEARCH_RESULTS: {
      let newSearchResults: SearchResults = [];
      const showMoreButton = !(action.payload.searchResults.length == 0);
      if (action.payload.append) {
        newSearchResults = [...state.searchResults, ...action.payload.searchResults];
      } else {
        if (action.payload.searchResults.length > 0) {
          newSearchResults = action.payload.searchResults;
        }
      }

      return {
        ...state,
        searchResults: newSearchResults,
        showMoreButton: showMoreButton,
      };
    }
    case SET_SHIP_STATISTICS: {
      return {
        ...state,
        shipStatistics: action.payload.statistics,
      };
    }
    case SET_ACCOUNT_ACHIEVEMENTS: {
      return {
        ...state,
        achievements: action.payload.achievements,
      };
    }
    case SET_CHARTS_DATA: {
      return {
        ...state,
        nationStatisticsMap: action.payload.nationStatisticsMap,
        vehicleTypeStatisticsMap: action.payload.vehicleTypeStatisticsMap,
        vehicleLevelStatisticsMap: action.payload.vehicleLevelStatisticsMap,
      };
    }
    case TOGGLE_FILTER: {
      return {
        ...state,
        filters: [...action.payload.filters],
      };
    }
    case SET_SHIP_TABLE_IS_STICKY: {
      return {
        ...state,
        isShipTableHeaderSticky: action.payload.isSticky,
      };
    }
    case SET_INVITED_ID: {
      const newIsInviteSendMap = { ...state.isInviteSendMap };
      newIsInviteSendMap[action.payload.id] = true;
      return {
        ...state,
        isInviteSendMap: newIsInviteSendMap,
      };
    }
    case SET_SHIP_FILTER_BY_TITLE_VALUE: {
      return {
        ...state,
        shipFilterByTitleValue: action.payload.shipFilterByTitleValue,
      };
    }
    default:
      return state;
  }
};
