import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import React, { memo } from 'react';

import { BasicColumn } from './Table';
import styles from './Table.scss';

export interface Cell_Props<Data> {
  index: number;
  data: Data;
  renderContent: (data: Data, index: number) => React.ReactNode;
}

const Cell = <Data extends BasicColumn>(props: Cell_Props<Data>) => {
  const { index, data, renderContent } = props;

  const cell = classnames(styles.cell, {
    [`cell-${data.selector}`]: true,
  });

  return <div className={cell}>{renderContent(data, index)}</div>;
};

export default memo(Cell, equal);
