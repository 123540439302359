import classnames from 'classnames';
import React, { memo } from 'react';

import bulma from '~/styles/bulma.scss';

interface IContainer {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

export const Container = memo((props: IContainer) => {
  const containerClassname = classnames(bulma.container, {
    [props.className]: !!props.className,
  });
  return (
    <div className={containerClassname} id={props.id}>
      {props.children}
    </div>
  );
});

interface IRow {
  children?: React.ReactNode;
  className?: string;
}

export const Row = memo((props: IRow) => {
  const columnsClassname = classnames(bulma.columns, {
    [props.className]: !!props.className,
  });

  return <div className={columnsClassname}>{props.children}</div>;
});

interface ICol {
  children?: React.ReactNode;
  size?: number;
  partial?: boolean;
  className?: string;
}

const getBulmaSize = (count: number) => {
  const index = count - 1;
  const sizes: Array<string> = [
    bulma['is-2'],
    bulma['is-4'],
    bulma['is-6'],
    bulma['is-8'],
    bulma['is-10'],
    bulma['is-12'],
  ];

  return sizes[index];
};

export const Col = memo((props: ICol) => {
  const classname = classnames(bulma.column, {
    [bulma[`is-${props.size}`]]: !!props.size && !props.partial,
    [getBulmaSize(props.size)]: !!props.size && props.partial,
    [props.className]: !!props.className,
  });

  return <div className={classname}>{props.children}</div>;
});
