import equal from 'fast-deep-equal/react';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsersSearch, searchInit, setSearchResults } from '~/Actions/ActionApp';
import BattleTypePicker from '~/Components/BattleTypePicker/BattleTypePicker';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import SearchPanel from '~/Components/SearchPanel/SearchPanel';
import SearchTable from '~/Components/SearchTable/SearchTable';
import { useMount, useWillMount } from '~/hooks';
import { State } from '~/Reducers';
import { getQueryParams } from '~/utils';

import styles from './Search.scss';

export interface SearchState {
  battleType: StatBattleType;
  searchResults: SearchResults;
  showMoreButton: boolean;
}

const stateSelector = (state: State): SearchState => {
  return {
    battleType: state.ReducerApp.battleType,
    searchResults: state.ReducerApp.searchResults,
    showMoreButton: state.ReducerApp.showMoreButton,
  };
};

const Search = () => {
  const prevQuery = useRef<string>('');
  const queryParams = getQueryParams();
  const state = useSelector<State, SearchState>(stateSelector, equal);
  const dispatch = useDispatch();

  const { searchResults, battleType, showMoreButton } = state;

  const [showResultTable, setShowResultTable] = useState<boolean>();

  const update = () => {
    if (queryParams && queryParams.query && prevQuery.current !== queryParams.query) {
      const query = `${queryParams.query}`;
      prevQuery.current = query;
      dispatch(getUsersSearch(query));
    }

    if (queryParams && queryParams.query) {
      setShowResultTable(queryParams.query !== '');
    }
  };

  useWillMount(update);

  useMount(() => {
    dispatch(searchInit());
    return () => {
      dispatch(setSearchResults([]));
    };
  });

  useEffect(update, [queryParams]);

  const loadMore = () => {
    if (queryParams && queryParams.query) {
      const query = `${queryParams.query}`;
      const lastItem = searchResults[searchResults.length - 1];
      const lastName = lastItem ? lastItem.name : undefined;
      if (lastName) {
        dispatch(getUsersSearch(query, lastName));
      }
    }
  };

  const onSearchEvent = (value: string) => {
    setShowResultTable(value && value !== '');
  };

  return (
    <Container className={styles.wrapper}>
      <Row>
        <Col size={12}>
          <div className={styles.header}>
            <SearchPanel onSearch={onSearchEvent} />
          </div>
        </Col>
      </Row>
      {searchResults.length > 0 ? (
        <Row>
          <Col size={4} className={styles.searchRow}>
            <BattleTypePicker noNavigation allTypes />
          </Col>
        </Row>
      ) : null}
      {showResultTable ? (
        <Row>
          <Col>
            <SearchTable
              data={searchResults}
              showMoreButton={showMoreButton}
              battleType={battleType}
              loadMore={loadMore}
            />
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

export default memo(Search);
