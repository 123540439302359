import { Store } from 'redux';

import { Actions } from '~/Actions';
import { SET_INVITED_ID } from '~/Actions/ActionApp';
import { PERSISTED_DATA_KEY } from '~/constants';
import { State } from '~/Reducers';

const allowedActionTypes: Array<string> = [SET_INVITED_ID];

const persistMiddleware = (store: Store<State, Actions>) => (next: any) => (action: Actions): State => {
  const result = next(action);
  if (allowedActionTypes.includes(action.type)) {
    const state = store.getState();
    const persistedState: PersistedState = {
      isInviteSendMap: state.ReducerApp.isInviteSendMap,
    };
    sessionStorage.setItem(PERSISTED_DATA_KEY, JSON.stringify(persistedState));
  }
  return result;
};

export default persistMiddleware;
