import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';

import styles from './ShipClass.scss';

const vehicleTypesMapSelector = (state: State) => state.ReducerApp.vehicleTypesMap;

export interface ShipClass_Props {
  type: string;
}

const ShipClass = (props: ShipClass_Props) => {
  const nationsMap = useSelector<State, VehicleTypesMap>(vehicleTypesMapSelector, equal);
  const { type } = props;

  const iconUrl = get(nationsMap, `[${type}].icons.default`, undefined);

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${iconUrl})`,
      }}
    />
  );
};

export default memo(ShipClass, equal);
