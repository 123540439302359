import Button from '@wg/wows-react-uikit/Button';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { navigateToProfile } from '~/Actions/ActionNavigation';
import Table from '~/Components/Table/Table';
import { NavigationPlaces } from '~/constants';
import { isInGame, roundNumbers } from '~/utils';
import { formatName } from '~/utils/players';

import styles from './SearchTable.scss';

export interface TableColumn {
  selector: SearchCellSelector;
  width?: number; // percent
}

export type TableColumns = Array<TableColumn>;

const columns: TableColumns = [
  {
    selector: 'number',
    width: 10,
  },
  {
    selector: 'name',
    width: 60,
  },
  {
    selector: 'battles_count',
    width: 15,
  },
  {
    selector: 'wins',
    width: 15,
  },
];

export interface SearchTable_Props {
  data: SearchResults;
  battleType: StatBattleType;
  showMoreButton: boolean;

  loadMore: () => void;
}

const SearchTable = (props: SearchTable_Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, battleType, showMoreButton } = props;

  const onRowClick = (item: SearchResult) => {
    dispatch(navigateToProfile(`${item.spa_id}`, NavigationPlaces.SEARCH));
  };

  const renderCell = (column: TableColumn, index: number) => {
    const item = data[index];
    const stats = get(item, `statistics[${battleType}]`);
    const battlesCount = get(stats, 'battles_count', '-');
    const wins = get(stats, 'wins', '-');
    const winsDisplay = wins === '-' ? '-' : roundNumbers((100 / battlesCount) * wins, true);
    switch (column.selector) {
      case 'wins': {
        return <div>{winsDisplay}</div>;
      }
      case 'battles_count': {
        return <div>{battlesCount}</div>;
      }
      case 'name': {
        return <div>{formatName(item.name)}</div>;
      }
      case 'number': {
        const number = index + 1;
        return <div>{number}</div>;
      }
      default: {
        return <div>{column.selector}</div>;
      }
    }
  };
  const renderHeaderCell = (column: TableColumn) => {
    switch (column.selector) {
      case 'wins': {
        return <div className={styles.winIcon}>{t('Victories:duplicated0')}</div>;
      }
      case 'battles_count': {
        return <div className={styles.battleIcon}>{t('Battles')}</div>;
      }
      case 'name': {
        return <div>{t('Username')}</div>;
      }
      case 'number': {
        return <div>{t('No.')}</div>;
      }
      default: {
        return <div>{column.selector}</div>;
      }
    }
  };

  const topOffset = isInGame ? 0.1 : 37;

  return (
    <>
      <Table
        columns={columns}
        items={data}
        renderCell={renderCell}
        renderHeaderCell={renderHeaderCell}
        onRowClick={onRowClick}
        topOffset={topOffset}
      />
      <div className={styles.loadMoreButtonWrapper}>
        {data.length > 0 && showMoreButton ? (
          <Button isFlat onClick={props.loadMore}>
            {t('Show more')}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default SearchTable;
