import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import { GLOSS } from '~/constants';
import preloaded from '~/preloaded';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${preloaded.urls.vortex}${GLOSS}`,
  credentials: 'include',
});

const client = new ApolloClient({
  cache: cache,
  link: link,
  name: 'profile',
  version: '1.0',
  queryDeduplication: false,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

client.cache.reset();

export default client;
