import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { dropAccount, dropFilters, getUsersSearch } from '~/Actions/ActionApp';
import history from '~/browserHistory';
import { DWH_EVENTS, Pages, ROUTES } from '~/constants';
import preloaded from '~/preloaded';
import { State } from '~/Reducers';
import dwhExport from '~/utils/dwhExport';

export const navigateToProfile = (id: string, place?: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getState: () => State,
) => {
  dispatch(dropFilters());
  dispatch(dropAccount());
  dwhExport.send(DWH_EVENTS.OPEN_PAGE, {
    name: Pages.STATISTICKS,
    from: place,
    profile_type: (preloaded.account && preloaded.account.id === +id) || !id ? 'own' : 'other',
  });
  history.push(`/statistics/${id}`);
};

export const navigateToSearchResults = (query: string, place?: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getState: () => State,
) => {
  dispatch(getUsersSearch(query));
  dwhExport.send(DWH_EVENTS.OPEN_PAGE, { name: Pages.SEARCH, from: place });
  history.push(`${ROUTES.ROOT}?query=${query}`);
};

export const navigateToRoute = (route: string, clb: () => void): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const { spaId } = state.ReducerApp;

  if (route == '/') {
    history.push(route);
  } else {
    history.push(`${route}/${spaId}`);
  }
  clb();
};
