import classnames from 'classnames';
import React, { memo, useRef } from 'react';
import { Sticky } from 'react-sticky';

import { useMount } from '~/hooks/index';

import { BasicColumn } from './Table';
import styles from './Table.scss';

export interface Header_Props<Column> {
  columns: Array<Column>;
  sort?: string;
  sortDirection?: SortDirection;
  renderCell: (column: Column) => React.ReactNode;
  onCellClick?: (column: Column) => void;
  onHeaderStickyStateChange?: (isSticky: boolean) => void;
  topOffset: number;
}

const Header = <Column extends BasicColumn>(props: Header_Props<Column>) => {
  const { columns, sort, sortDirection, topOffset, onCellClick, renderCell, onHeaderStickyStateChange } = props;

  const isStickyRef = useRef<boolean>(false);

  const onClick = (column: Column) => () => {
    if (onCellClick) {
      onCellClick(column);
    }
  };

  useMount(() => {
    return () => {
      if (onHeaderStickyStateChange) {
        onHeaderStickyStateChange(false);
      }
    };
  });

  return (
    <Sticky topOffset={-props.topOffset}>
      {({ style, isSticky }) => {
        const stickyStyle: any = style;
        const header = classnames(styles.header, {
          [styles.stickyHeader]: isSticky,
        });
        if (isStickyRef.current !== isSticky) {
          if (onHeaderStickyStateChange) {
            onHeaderStickyStateChange(isSticky);
          }
        }
        isStickyRef.current = isSticky;
        return (
          <div
            style={{
              ...stickyStyle,
              zIndex: 850,
              top: isSticky ? `${topOffset}px` : undefined,
            }}
          >
            <div className={header}>
              {columns.map((column, index) => {
                const isActive = !!sort && !!column.selector && sort === column.selector;
                const headerCell = classnames(styles.headerCell, {
                  [styles.headerCellActive]: isActive,
                  [`header-cell-${column.selector}`]: true,
                });
                const arrow = classnames(styles.arrow, {
                  [styles.arrowActive]: isActive && sortDirection !== 0,
                  [styles.arrowUp]: sortDirection === -1,
                });

                return (
                  <div key={`header-cell-${index}`} className={headerCell} onClick={onClick(column)}>
                    {renderCell(column)}
                    <div className={arrow} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </Sticky>
  );
};

export default memo(Header);
