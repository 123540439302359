import Processing from '@wg/wows-react-uikit/Processing';
import equal from 'fast-deep-equal/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';

export interface Spinner_State {
  fetching: FetchingMap;
}

const stateSelector = (state: State) => {
  return {
    fetching: state.ReducerApp.fetching,
  };
};

const Spinner = () => {
  const { fetching } = useSelector<State, Spinner_State>(stateSelector, equal);
  const { t } = useTranslation();

  let isFetching = false;

  for (const key in fetching) {
    if (!isFetching && fetching[key]) {
      isFetching = true;
    }
  }

  return <Processing isFetching={isFetching} />;
};

export default Spinner;
