import wgcm from '@wg/wowscm';

const inGameUserAgentKeyword = 'WOWS/1.0';

const appDiv = document.getElementById('app');
const cmWrapper = document.getElementById('cm-wrapper');

export function isInGame() {
  return navigator.userAgent.includes(inGameUserAgentKeyword);
}

export default function initCommonMenu() {
  let userId = null;
  let userName = null;
  const accountInfo = window.preloaded.account;
  if (accountInfo) {
    userId = accountInfo.id;
    userName = accountInfo.nickname;
  }
  if (isInGame()) {
    document.getElementById(window.cm_settings.menuElementId).remove();
  } else {
    wgcm(userId, userName);
    if (cmWrapper) {
      cmWrapper.classList.add('cm-wrapper-visible');
    }
    if (appDiv) {
      appDiv.classList.add('not-ingame');
    }
  }
}
