import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl';
import Dialog from '@wg/wows-react-uikit/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AccessCodeLink, setVisibility } from '~/Actions/ActionVisibility';
import ProfilePrivacyModal from '~/Components/ProfilePrivacy/ProfilePrivacyModal';
import { playButtonDialogClickSound } from '~/helpers/audioApi';

import style from './ProfilePrivacy.scss';

interface ProfilePrivacyInterfaceProps {
  visibilitySettings: VisibilitySettings;
  id: string;
  isClanMember: boolean;
}

const VisibilityStatus = {
  unhide: 'unhide',
  hide: 'hide',
};

const ProfilePrivacy: React.FC<ProfilePrivacyInterfaceProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isPublic, setPublic] = useState<boolean>(false);
  const [isPrivate, setPrivate] = useState<boolean>(false);
  const [isLink, setLink] = useState<boolean>(false);
  const [isCheckBoxVisible, setCheckBoxVisible] = useState<boolean>(false);
  const [isCheckBox, setCheckBox] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<LocationParams>();
  const origin = window.location.origin;
  const linkValue = `${origin}/statistics/${props.id}/ac/${accessCode}/`;
  const isClanMember = props.isClanMember;

  useEffect(() => {
    setVisibilitySettings();
  }, []);

  useEffect(() => {
    setAccessCode(props.visibilitySettings.access_code);
  }, [props.visibilitySettings.access_code]);

  const setVisibilitySettings = () => {
    if (props.visibilitySettings.status === VisibilityStatus.unhide) {
      setPublic(true);
    } else {
      if (props.visibilitySettings.access_code) {
        setLink(true);
        setAccessCode(props.visibilitySettings.access_code);
      } else {
        setPrivate(true);
      }
      setCheckBoxVisible(true);
      setCheckBox(props.visibilitySettings.access_clan);
    }
  };

  const clear = () => {
    setPublic(false);
    setPrivate(false);
    setLink(false);
    setCheckBoxVisible(false);
  };

  const onHideDialog = () => {
    setIsVisible(false);
  };

  const onVisibleDialog = () => {
    playButtonDialogClickSound();
    setIsVisible(true);
  };

  const onChangeCheckbox = () => {
    setCheckBox(!isCheckBox);
  };

  const onRefreshLink = () => {
    dispatch(AccessCodeLink(params.id));
  };

  const openInBrowser = () => {
    openExternalUrl(linkValue);
  };

  const onSaveSettings = () => {
    const visibilitySettings = {
      status: isPublic ? VisibilityStatus.unhide : VisibilityStatus.hide,
      access_clan: isCheckBox,
      access_code: accessCode,
    };

    if (accessCode === '') {
      delete visibilitySettings.access_code;
    }

    dispatch(setVisibility(params.id, visibilitySettings));
  };

  const onChange = (name: string) => {
    clear();

    switch (name) {
      case 'public': {
        setPublic(true);
        break;
      }
      case 'private': {
        setPrivate(true);
        setCheckBoxVisible(true);
        setAccessCode('');
        break;
      }
      case 'link': {
        dispatch(AccessCodeLink(params.id));
        setLink(true);
        setCheckBoxVisible(true);
        break;
      }
    }
  };

  return (
    <>
      <div className={style.privacyIndicator} onClick={onVisibleDialog}>
        <span className={style.privacyTitle}>{t('Profile privacy:')}</span>
        {isPrivate && (
          <span className={style.privacyProfile}>{isCheckBox ? t('Private (available to the Clan)') : t('Private')}</span>
        )}
        {isPublic && <span className={style.publicProfile}>{t('Public')}</span>}
        {isLink && (
          <span className={style.linkProfile}>{isCheckBox ? t('Via link (available to the Clan)') : t('Via link')}</span>
        )}
      </div>
      <Dialog
        className={style.modal}
        size={'info'}
        isOverlay={false}
        isVisible={isVisible}
        hideDialog={onHideDialog}
        content={
          <ProfilePrivacyModal
            isPrivate={isPrivate}
            isLink={isLink}
            isPublic={isPublic}
            isCheckBox={isCheckBox}
            onHideDialog={onHideDialog}
            onChange={onChange}
            onSaveSettings={onSaveSettings}
            onChangeCheckbox={onChangeCheckbox}
            isCheckBoxVisible={isCheckBoxVisible}
            linkValue={linkValue}
            clear={clear}
            onRefreshLink={onRefreshLink}
            setVisibilitySettings={setVisibilitySettings}
            openInBrowser={openInBrowser}
            isClanMember={isClanMember}
          />
        }
      />
    </>
  );
};

export default ProfilePrivacy;
